import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Autocomplete, Box, Grid, Skeleton, Typography, createFilterOptions } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { format } from "date-fns";
import React from "react";
// @ts-ignore
import { ReactHeight } from "react-height";

import CheckIcon from "shared/assets/icons/check.svg";
import TrashIcon from "shared/assets/icons/trash.svg";
import ResolveButton from "shared/components/Buttons/ResolveButton/ResolveButton";
import { DAY_TYPES } from "shared/constants";

import CustomizedSelect from "../Select";
import useWeekPlanLogic from "./hooks/useWeekPlanLogic";
import {
  AutocompleteItem,
  AutocompleteItemIcons,
  AutocompleteItemText,
  CustomIcon,
  CustomizedBadge,
  CustomizedCollapse,
  CustomizedTextField,
  Day,
  RemoveButton,
  Room,
  Row,
  WeekPlanContainer
} from "./style/weekPlanStyle";

type WeekPlanProps = {
  details: {
    date: string;
    seatId?: string;
    type?: string;
    workstationName?: string;
    dayType?: string;
  };
  index: string;
  selectedIndex: string;
  setSelectedIndex: (index: string) => void;
  selectedRooms?: any[];
  handleChangeRoom: (item: any, date: any, reason: any, dayType: any) => void;
  handleChangeDayType: (dayType: string, date: string) => void;
  handleApplySelection: (workstation: any, date: any, dayType: any) => void;
  onRemoveAssignmentClick?: (date: string) => void;
  dayTypes?: string[];
  isLoading?: boolean;
  disabled?: boolean;
};

const GREEN_COLOR = "#108000";

const filterOptions = createFilterOptions({
  stringify: (option: any) => option.value
});

const WeekPlan = ({
  details,
  index,
  selectedIndex,
  setSelectedIndex,
  handleChangeDayType,
  selectedRooms,
  onRemoveAssignmentClick,
  dayTypes,
  isLoading,
  disabled,
  ...props
}: WeekPlanProps) => {
  const {
    setContainerHeight,
    getMonthAndDate,
    getRoomColor,
    getDayTypeText,
    handleExpandClick,
    containerHeight,
    getDayName,
    currentDayData,
    currentRoomData,
    workstationsByBuilding,
    onSelectWorkstation,
    onApplyDailyAssignment,
    isWorkstationByBuildingsLoading,
    isApplyDisabled
  } = useWeekPlanLogic({ details, selectedIndex, setSelectedIndex, selectedRooms, dayTypes, ...props });

  const userStatuses = [
    { option: "Office", value: DAY_TYPES.office },
    { option: "Home", value: DAY_TYPES.home },
    { option: "Travel", value: DAY_TYPES.travel },
    { option: "Sick", value: DAY_TYPES.sick },
    { option: "Off", value: DAY_TYPES.off },
    { option: "Non-working", value: DAY_TYPES.nonWorking, disabled: true }
  ];

  const renderAutocompleteItem = (optionProps: any, option: any) => {
    const selected = optionProps["aria-selected"];

    return (
      <AutocompleteItem {...optionProps} key={option.id} selected={selected}>
        {/* @ts-ignore */}
        {selected ? <AutocompleteItemIcons src={CheckIcon} /> : null}

        {/* @ts-ignore */}
        <AutocompleteItemText selected={selected}>{option.value}</AutocompleteItemText>
      </AutocompleteItem>
    );
  };

  return (
    <ReactHeight onHeightReady={(height: any) => setContainerHeight(height)}>
      {/* @ts-ignore */}
      <WeekPlanContainer expanded={selectedIndex === index}>
        {!isLoading ? (
          // @ts-ignore
          <Day selected={selectedIndex === index}>
            {/* @ts-ignore */}
            <Typography color={selectedIndex === index ? "white" : "#00203E"} fontWeight="400" variant="body1">
              {format(new Date(details.date), "EEEE")?.toUpperCase()}
            </Typography>
          </Day>
        ) : (
          <Skeleton width="100%" height="20px" />
        )}
        {!isLoading ? (
          <>
            <Box>
              <Typography color="#8D9096" fontSize="small" variant="body1">
                {getMonthAndDate(details.date)}
              </Typography>
            </Box>
            <Box height={"58px"}>
              <Room color={getRoomColor(details.workstationName)}>
                <CustomizedBadge
                  color="success"
                  variant="dot"
                  invisible={getRoomColor(details.workstationName) !== GREEN_COLOR}
                >
                  <Typography textAlign="center" variant="body1">
                    {details.workstationName ? `Room ${details.workstationName}` : getDayTypeText(details.dayType)}
                  </Typography>
                </CustomizedBadge>
              </Room>
            </Box>
            <IconButton onClick={() => handleExpandClick(index)}>
              {index === selectedIndex ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </>
        ) : (
          <Skeleton width="100%" height={"88px"} />
        )}
      </WeekPlanContainer>
      <CustomizedCollapse
        in={selectedIndex === index}
        timeout={{ appear: 100, enter: 100, exit: 150 }}
        easing={{ enter: "200ms ease", exit: "150ms ease" }}
        unmountOnExit
        collapsedSize="500px"
        orientation="horizontal"
        // @ts-ignore
        containerHeight={containerHeight}
      >
        <Box p={2}>
          <Typography color="#6B6C72">{getDayName(details.date)}</Typography>
          {details?.seatId ? (
            <>
              <Grid container width="100%" alignItems="center">
                <Grid item xs={5}>
                  <Typography variant="h6">{`Change this day's assignment`}</Typography>
                </Grid>
                <Grid item xs={7}>
                  <RemoveButton onClick={() => onRemoveAssignmentClick?.(details.date)} disabled={isLoading}>
                    {/* @ts-ignore */}
                    <CustomIcon src={TrashIcon} disabled={isLoading} />
                    <Typography>Remove daily assignment</Typography>
                  </RemoveButton>
                </Grid>
              </Grid>
            </>
          ) : currentDayData?.type?.toLowerCase() === "office" ? (
            <Grid container alignItems="center" mt={1}>
              <Grid item xs={5}>
                <Typography variant="h6">{"Assign a daily room"}</Typography>
              </Grid>
              <Grid item xs={7}>
                <Autocomplete
                  disablePortal
                  forcePopupIcon={false}
                  options={workstationsByBuilding}
                  filterOptions={filterOptions}
                  getOptionLabel={option => (option.value ? option.value : "")}
                  getOptionDisabled={option => option?.disabled}
                  onChange={onSelectWorkstation}
                  size="small"
                  value={currentRoomData?.item}
                  fullWidth
                  loading={isWorkstationByBuildingsLoading}
                  disabled={currentDayData?.type?.toLowerCase() !== "office" || disabled}
                  renderOption={renderAutocompleteItem}
                  renderInput={params => <CustomizedTextField {...params} label="" placeholder={"Search room"} />}
                />
              </Grid>
            </Grid>
          ) : null}
          <Grid container alignItems="center" mt={1}>
            <Grid item xs={5}>
              <Typography variant="h6">Change day type</Typography>
            </Grid>
            <Grid item xs={7}>
              <Row>
                <CustomizedSelect
                  name={`dayType-${details.date}`}
                  handleChange={(e: any) => handleChangeDayType(e, details.date)}
                  values={userStatuses}
                  selectedValue={[currentDayData?.type || ""] as any}
                  // @ts-ignore
                  fullWidth={true}
                  disabled={!!currentRoomData?.item || !!details.seatId}
                  placeholder="Select day type"
                  isMultiple={false}
                />

                {/* @ts-ignore */}
                <ResolveButton
                  text="Apply"
                  variant={isApplyDisabled || disabled ? "outlined" : "contained"}
                  onClick={onApplyDailyAssignment}
                  disabled={isApplyDisabled || disabled}
                />
              </Row>
            </Grid>
          </Grid>
        </Box>
      </CustomizedCollapse>
    </ReactHeight>
  );
};

export default React.memo(WeekPlan);
