import { Box, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";

import { LOGIN_LOGO_SIZE } from "shared/constants";

import useConfirmationCodePageLogic from "./hooks/useConfirmationCodePageLogic";
import {
  CustomizedButton,
  CustomizedContainer,
  CustomizedDiv,
  CustomizedErrorText,
  CustomizedIcon,
  CustomizedSubmitButton,
  CustomizedTextField,
  LogoImg
} from "./style/confirmationCodePageStyle";

const ConfirmationCodePage = props => {
  const {
    handleSubmit,
    errorMsg,
    handleChange,
    code,
    isLoading,
    onSendSms,
    onSendEmail,
    smsCountdown,
    emailCountdown,
    logoUrl,
    isLoadingCompanyStyle,
    logoSize
  } = useConfirmationCodePageLogic(props);

  return (
    <CustomizedDiv>
      <CustomizedContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          {!isLoadingCompanyStyle ? (
            <CustomizedIcon
              sx={{ width: logoSize.width, height: logoSize.height, top: (LOGIN_LOGO_SIZE + 40 - logoSize.height) / 2 }}
            >
              <LogoImg src={logoUrl} alt="Company logo" />
            </CustomizedIcon>
          ) : null}
          <Typography component="h1" variant="h4" padding="30px">
            A code has been sent to your phone.
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, position: "relative" }}>
            {errorMsg && (
              <CustomizedErrorText>
                <Typography variant="body1" gutterBottom color="red">
                  {errorMsg}
                </Typography>
              </CustomizedErrorText>
            )}
            <CustomizedTextField
              margin="normal"
              required
              fullWidth
              onChange={handleChange}
              id="code"
              label=""
              placeholder="Please enter code"
              name="code"
              autoFocus
              size="small"
              value={code}
            />
            <CustomizedSubmitButton
              type="submit"
              fullWidth
              disabled={!code || isLoading}
              variant="contained"
              sx={{ mt: 4, mb: 2 }}
            >
              Log In
            </CustomizedSubmitButton>
            <Grid container mt={8}>
              <Grid item xs>
                <CustomizedButton variant="body1" onClick={onSendSms} disabled={smsCountdown.enable}>
                  {smsCountdown.enable ? smsCountdown.display : "Resend code"}
                </CustomizedButton>
              </Grid>
              <Grid item>
                <CustomizedButton variant="body1" onClick={onSendEmail} disabled={emailCountdown.enable}>
                  {emailCountdown.enable ? emailCountdown.display : "Send to email"}
                </CustomizedButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </CustomizedContainer>
    </CustomizedDiv>
  );
};

ConfirmationCodePage.propTypes = {
  history: PropTypes.object
};

export default withRouter(ConfirmationCodePage);
