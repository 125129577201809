import CheckIcon from "@mui/icons-material/Check";
import { Box, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material";
import styled from "@mui/material/styles/styled";
import React, { FC, useState } from "react";

import { DAY_TYPES } from "shared/constants";
import { capitalizeFirstLetter } from "shared/lib/stringsLib";

type Props = {
  item: {
    date: string;
    dayType: string;
    hasCheckedIn: boolean;
  };
  employeeId: string;
  onChangeDayType: (
    data: { dayType: string; employeeId: string; date: string },
    cb: (isSuccess: boolean) => void
  ) => void;
};

const selectOptions = Object.values(DAY_TYPES).map(option => {
  if (option === DAY_TYPES.armyService) {
    return {
      option: "Army duty",
      value: DAY_TYPES.armyService
    };
  }

  return {
    option,
    value: option
  };
});

const getStyle = (dayType: string, hasCheckedIn: boolean) => {
  if (dayType === DAY_TYPES.office) {
    return hasCheckedIn
      ? { background: "#00B4F0", color: "#fff", fontWeight: "bold" }
      : { background: "#CCF2FF", color: "#00B4F0", fontWeight: "bold" };
  }

  if (dayType === DAY_TYPES.home) {
    return {
      background: "#D3F9CD"
    };
  }

  if (dayType === DAY_TYPES.nonWorking || dayType === DAY_TYPES.armyService) {
    return {
      background: "#ECEEF1"
    };
  }

  return {
    background: "#BABEC5"
  };
};

const AttendanceDays: FC<Props> = ({ item, onChangeDayType, employeeId }) => {
  const [selectedDayType, setSelectedDayType] = useState(item.dayType);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChangeDayType = (event: any) => {
    const {
      target: { value }
    } = event;

    if (value === selectedDayType) return;

    setLoading(true);

    onChangeDayType({ dayType: value, employeeId, date: item.date }, (isSuccess: boolean) => {
      if (isSuccess) {
        setSelectedDayType(value);
      }

      setLoading(false);
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setTimeout(() => {
      setOpen(true);
    }, 100);
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-evenly" minWidth="20%">
      <Select
        disabled={loading}
        value={selectedDayType}
        onChange={handleChangeDayType}
        fullWidth
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        renderValue={selected =>
          capitalizeFirstLetter(selectOptions.find(option => option.value === selected)?.option || "")
        }
        input={
          <CustomizedOutlineInput
            className={loading ? "loading" : undefined}
            label=""
            sx={getStyle(selectedDayType, item.hasCheckedIn)}
          />
        }
      >
        {selectOptions.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {selectedDayType === option.value && (
              <CheckIcon sx={{ color: "#000", paddingRight: "8px" }} fontSize="medium" />
            )}
            <ListItemText primary={capitalizeFirstLetter(option.option)} />
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

const CustomizedOutlineInput = styled(OutlinedInput)(({ theme }) => ({
  "&.loading": {
    opacity: 0.7
  },

  marginRight: theme.spacing(0.75),
  border: "none",
  color: "#6B6C72",
  overflow: "hidden",
  borderRadius: theme.spacing(1.25),

  "& > fieldset": {
    borderColor: "transparent",
    overflow: "hidden",
    cursor: "pointer"
  },

  "& > .MuiSelect-outlined.MuiOutlinedInput-input": {
    padding: theme.spacing(1.25, 5)
  }
}));

export default AttendanceDays;
