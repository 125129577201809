import { Avatar, Box, Button, Divider, Grid, Typography } from "@mui/material";
import React from "react";

import TrashIcon from "shared/assets/icons/trash.svg";
import AvatarInitials from "shared/components/AvatarInitials/AvatarInitials";
import ConfirmDialog from "shared/components/Dialogs/ConfirmDialog";
import FormInputAutocomplete from "shared/components/FormInputs/Autocomplete/FormInputAutocomplete";
import FormInputDate from "shared/components/FormInputs/Date/FormInputDate";
import FormInputDropdown from "shared/components/FormInputs/Dropdown";
import FreeSoloAutocomplete from "shared/components/FormInputs/FreeSoloAutocomplete/FreeSoloAutocomplete";
import FormInputPhone from "shared/components/FormInputs/PhoneNumber/FormInputPhone";
import FormInputRadio from "shared/components/FormInputs/Radio/FormInputRadio";
import FormInputText from "shared/components/FormInputs/Text/FormInputText";
import Sidebar from "shared/components/Sidebar/Sidebar";
import { RGX_EMAIL } from "shared/constants";
import { nonWorkingReasonsOptions } from "shared/constants/optionsConstants";
import { getFullName } from "shared/lib/getters";
import { isEmptyObject } from "shared/lib/validation";

import useUserEditPageLogic from "./hooks/useUserEditPageLogic";
import { DeleteButton, DeleteIcon, DeleteText, HelperText, RemoveButton } from "./style/userEditPageStyle";

type UserInfoPageProps = {
  history: any;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  userId: number;
  isEdit: boolean;
};

const officeDaysList = [
  { option: 0, value: 0 },
  { option: 1, value: 1 },
  { option: 2, value: 2 },
  { option: 3, value: 3 },
  { option: 4, value: 4 },
  { option: 5, value: 5 }
];

const genderData = [
  { value: "f", radioLabel: "Female" },
  { value: "m", radioLabel: "Male" },
  { value: "n", radioLabel: "Non-binary" }
];

const deleteUserText = (
  <div>
    <span>If you choose to delete this user:</span>
    <ul>
      <li>The user will not have access to the BWith app</li>
      <li>Other users will not be able to see this user in their friends list</li>
      <li>The user will not appear in any search results</li>
      <li>If the user has a room, it will become vacant</li>
    </ul>
    <div style={{ marginTop: "16px" }}>
      <span>
        Note: if this user is on long term or maternity leave, use the ‘Long term leave’ from the user info side bar
        instead.
      </span>
    </div>
  </div>
);

const DELETE_TITLE = "Delete User";

const UserInfoPage = ({ history, isEdit, isOpen, setIsOpen, userId }: UserInfoPageProps) => {
  const {
    isLoading,
    error,
    isSuccess,
    currentUser,
    reset,
    image,
    firstName,
    handleSubmit,
    onSubmit,
    lastName,
    getImageSrc,
    register,
    errors,
    control,
    emailValidate,
    titleList,
    filteredUserList,
    departmentList,
    cityList,
    siteList,
    buildingList,
    floorsOptions,
    floorAccordance,
    workstationId,
    workstationsByFloorOptions,
    neighborhoodList,
    assignedUserName,
    floor,
    showHelpText,
    handleClearWorkstation,
    onDeleteUser,
    showDeleteDialog,
    onDeleteHandler,
    onRejectDeleteHandler,
    setShowDialog,
    building
  } = useUserEditPageLogic({
    isEdit,
    isOpen,
    userId
  });

  return (
    <div>
      {/* @ts-ignore */}
      <Sidebar
        history={history}
        onResolve={handleSubmit(onSubmit)}
        resolveLabel={isEdit ? "Update user" : "Add user"}
        resetForm={reset}
        isDrawer
        shouldValidate
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isLoading={isLoading}
        error={error}
        isSuccess={isSuccess}
        data={currentUser}
      >
        <Box>
          <Typography variant="h5" fontWeight="700">
            {isEdit ? "Edit user information" : "Add new user"}
          </Typography>
        </Box>
        <Grid container rowSpacing={1} spacing={3}>
          <Grid item xs={12} mt={3} mb={1}>
            <Box display="flex" alignItems="center">
              {isEdit && !image ? (
                <AvatarInitials firstName={firstName} lastName={lastName} />
              ) : (
                <Avatar src={getImageSrc()} sx={{ width: "71px", height: "71px", marginRight: "16px" }} />
              )}
              <input
                accept="image/png, image/jpeg"
                style={{ display: "none" }}
                id="raised-button-file"
                type="file"
                {...register("image")}
              />
              <label htmlFor="raised-button-file">
                {/* @ts-ignore */}
                <Button variant="raised" component="span">
                  <Typography>Upload profile image</Typography>
                </Button>
              </label>
            </Box>
            {/* @ts-ignore */}
            {errors?.file && (
              <Box pt={1}>
                <Typography variant="caption" color="#d32f2f">
                  Image is required
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={6}>
            {/* @ts-ignore */}
            <FormInputText
              label="First name*"
              control={control}
              name="firstName"
              rules={{ required: "First name is required" }}
              placeholder="Enter name"
            />
          </Grid>
          <Grid item xs={6}>
            {/* @ts-ignore */}
            <FormInputText
              label="Last name*"
              control={control}
              name="lastName"
              rules={{ required: "Last name is required" }}
              placeholder="Enter name"
            />
          </Grid>
          <Grid item xs={6}>
            {/* @ts-ignore */}
            <FormInputText
              label="Email address*"
              control={control}
              name="email"
              rules={{
                required: "Email is required",
                pattern: { value: RGX_EMAIL, message: "Invalid email" },
                validate: (value: string) => emailValidate(value)
              }}
              placeholder="Enter email"
            />
          </Grid>
          <Grid item xs={6}>
            {/* @ts-ignore */}
            <FormInputPhone
              label="Phone number*"
              control={control}
              name="phone"
              rules={{ required: "Phone number is required" }}
            />
          </Grid>
          <Grid item xs={6}>
            {/* @ts-ignore */}
            <FormInputText
              label="Display name (optional)"
              control={control}
              name="localDisplayName"
              placeholder="Enter display name"
            />
          </Grid>
          <Grid item xs={6}>
            {/* @ts-ignore */}
            <FreeSoloAutocomplete
              label="Title*"
              control={control}
              name="title"
              rules={{ required: "Title is required" }}
              data={titleList}
              placeholder="Enter title"
            />
          </Grid>
          <Grid item xs={6}>
            {/* @ts-ignore */}
            <FormInputAutocomplete
              label="Reports to*"
              control={control}
              name="managerId"
              rules={{ required: "Field is required" }}
              data={filteredUserList}
              placeholder="Search by name"
            />
          </Grid>
          <Grid item xs={6}>
            {/* @ts-ignore */}
            <FreeSoloAutocomplete
              label="Department*"
              control={control}
              name="department"
              data={departmentList}
              placeholder="Select department"
              rules={{ required: "Department is required" }}
            />
          </Grid>
          <Grid item xs={6}>
            {/* @ts-ignore */}
            <FormInputDate label="Date of birth (optional)" control={control} name="dateOfBirth" showYearDropdown />
          </Grid>
          <Grid item xs={6}>
            {/* @ts-ignore */}
            <FormInputDate
              label="Employment start date (optional)"
              control={control}
              name="employmentDate"
              showYearDropdown
            />
          </Grid>
          <Grid item xs={6}>
            {/* @ts-ignore */}
            <FreeSoloAutocomplete
              label="City of residence (optional)"
              control={control}
              name="city"
              data={cityList}
              placeholder="Enter city"
            />
          </Grid>
          <Grid item xs={6}>
            {/* @ts-ignore */}
            <FormInputRadio label="Gender (optional)" control={control} name="gender" data={genderData} />
          </Grid>
          <Grid item width="100%" mt={3} mb={2}>
            <Divider color="#8D9096" />
          </Grid>
          <Grid item xs={12}>
            {/* @ts-ignore */}
            <FormInputDropdown
              label="Site*"
              control={control}
              name="site"
              rules={{ required: "Site is required" }}
              data={siteList}
              styles={{ maxWidth: "50%" }}
              isMultiple={false}
              placeholder="Select site"
            />
          </Grid>
          <Grid item xs={12}>
            {/* @ts-ignore */}
            <FormInputDropdown
              label="Building*"
              control={control}
              name="building"
              rules={{ required: "Building is required" }}
              data={buildingList}
              styles={{ maxWidth: "50%" }}
              isMultiple={false}
              placeholder="Select building"
            />
          </Grid>
          <Grid item xs={12}>
            {/* @ts-ignore */}
            <FormInputDropdown
              label="Floor*"
              control={control}
              name="floor"
              rules={{ required: "Floor is required" }}
              data={floorsOptions}
              styles={{ maxWidth: "50%" }}
              isMultiple={false}
              placeholder="Select floor"
              disabled={!building}
            />
            {!floorAccordance && (
              <HelperText>
                <Typography variant="body2">
                  {`To assign this workstation, please change the Floor to ${(workstationId as any).floor}.`}
                </Typography>
              </HelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            {/* @ts-ignore */}
            <FormInputAutocomplete
              label="Neighborhood"
              control={control}
              name="neighborhood"
              data={neighborhoodList}
              styles={{ maxWidth: "50%" }}
              placeholder="Select neighborhood"
            />
          </Grid>
          <Grid item xs={6}>
            {/* @ts-ignore */}
            <FormInputDropdown
              label="Office days per week*"
              control={control}
              name="officeDays"
              rules={{ required: "Field is required" }}
              data={officeDaysList}
              isMultiple={false}
              placeholder="Select number of days"
            />
          </Grid>
          <Grid item xs={6}>
            {/* @ts-ignore */}
            <FormInputDropdown
              label="Long term absence: "
              control={control}
              name="nonWorkingReason"
              data={nonWorkingReasonsOptions}
              isMultiple={false}
              placeholder="Select"
            />
          </Grid>
          <Grid item xs={6}>
            {/* @ts-ignore */}
            <FormInputText
              label="Entrance control ID"
              control={control}
              name="entranceId"
              placeholder="Enter the entrance controller ID"
            />
          </Grid>
          <Grid item xs={6}>
            {/* @ts-ignore */}
            <FormInputText
              label="Time reporting ID"
              control={control}
              name="timeWatchId"
              placeholder="Enter the timesheets system ID"
            />
          </Grid>
          <Grid item xs={12}>
            {/* @ts-ignore */}
            <FormInputAutocomplete
              label="Assign permanent workstation (optional)"
              control={control}
              name="workstationId"
              data={workstationsByFloorOptions}
              placeholder="Search name or number"
              disabled={!floor}
            />
            {!floorAccordance && (
              <HelperText>
                <Typography variant="body2">
                  {`The selected workstation is not on the same floor as this user. To assign this workstation, please change the Floor to ${(workstationId as any).floor}.`}
                </Typography>
              </HelperText>
            )}
            {assignedUserName && showHelpText && (
              <HelperText>
                <Typography variant="body2">{`Room ${
                  (workstationId as any).room
                } is already assigned to ${assignedUserName}. Click the ${
                  isEdit ? "Update" : "Add user"
                } button to reassign this room to ${
                  isEdit ? getFullName(firstName, lastName) : "this user"
                }`}</Typography>
              </HelperText>
            )}
          </Grid>
          {isEdit && (
            <Grid item xs={12}>
              <RemoveButton disabled={!workstationId || isEmptyObject(workstationId)} onClick={handleClearWorkstation}>
                {/* @ts-ignore */}
                <img src={TrashIcon} />
                <Typography>Remove assignment</Typography>
              </RemoveButton>
            </Grid>
          )}

          {isEdit ? (
            <>
              <Grid item width="100%" mt={3} mb={2}>
                <Divider color="#8D9096" />
              </Grid>

              <Grid item xs={12}>
                <DeleteButton onClick={onDeleteUser}>
                  <DeleteIcon />
                  <DeleteText>Delete user</DeleteText>
                </DeleteButton>

                <DeleteText>
                  This action cannot be undone. Use it only for removing employees who no longer work for the company.
                </DeleteText>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Sidebar>

      <ConfirmDialog
        showDialog={showDeleteDialog}
        resolve={onDeleteHandler}
        reject={onRejectDeleteHandler}
        title={`${DELETE_TITLE} ${currentUser.firstName} ${currentUser.lastName}?`}
        text={deleteUserText}
        rowName={null}
        rowId={null}
        setShowDialog={setShowDialog}
      />
    </div>
  );
};

export default UserInfoPage;
