import { addDays, startOfWeek } from "date-fns";
import { useSelector } from "react-redux";

import { selectCompanyAndSiteSettings } from "core/selectors";

import { isCustomWeekend } from "shared/lib/dateUtils";

export default function useWorkWeekDates() {
  const { first_day_of_week } = useSelector(selectCompanyAndSiteSettings);

  const weekStartsOn = first_day_of_week === "Sunday" ? 0 : 1;

  let startDate = startOfWeek(new Date(), { weekStartsOn });

  // If today is a weekend, move to the next week
  if (isCustomWeekend(new Date(), weekStartsOn)) {
    startDate = addDays(startDate, 7);
  }

  const workDays = [];
  const weekend = [];
  const prevWorkDays = [];
  const prevWeekend = [];

  for (let i = 0; i < 5; i++) {
    workDays.push(addDays(startDate, i));
    prevWorkDays.push(addDays(startDate, i - 7));
  }

  for (let i = 5; i < 7; i++) {
    weekend.push(addDays(startDate, i));
    prevWeekend.push(addDays(startDate, i - 7));
  }

  return {
    workDays,
    weekend,
    startWeek: startDate,
    endWeek: addDays(startDate, 6),
    prevStartWeek: addDays(startDate, -7),
    prevEndWeek: addDays(startDate, -1),
    prevWorkDays,
    prevWeekend
  };
}
