import { apiSlice } from "core/api/apiSlice";

export const adminSettingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    nationalHolidays: build.query({
      query: () => ({
        url: "/preferences/api/v1/holidays",
        method: "GET"
      }),
      providesTags: ["Holidays"]
    }),
    nationalHolidaysById: build.mutation({
      query: ({ id }) => ({
        url: `/preferences/api/v1/holidays/${id}`,
        method: "GET"
      })
    }),
    createNationalHolidays: build.mutation({
      query: ({ siteIds, date, name }) => ({
        url: "/preferences/api/v1/holidays",
        method: "POST",
        body: {
          siteIds,
          date,
          name
        }
      }),
      invalidatesTags: ["Holidays"]
    }),
    deleteNationalHolidays: build.mutation({
      query: ({ id }) => ({
        url: `/preferences/api/v1/holidays/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Holidays"]
    }),
    companySites: build.query({
      query: () => ({
        url: "/employees/sites",
        method: "GET"
      })
    }),
    roles: build.query({
      query: () => ({
        url: `/employees/api/v1/roles`,
        method: "GET"
      })
    }),
    roleByName: build.mutation({
      query: ({ role }) => ({
        url: `/employees/api/v1/roles/${role}`,
        method: "GET"
      })
    }),
    routeResources: build.query({
      query: () => ({
        url: "/employees/api/v1/resources",
        method: "GET"
      })
    }),
    companyStyles: build.query({
      query: ({ email }) => ({
        url: "/employees/companyStyles",
        method: "GET",
        params: {
          email
        }
      })
    }),
    allowedDepartments: build.mutation({
      query: ({ id }) => ({
        url: `/employees/users/${id}/allowedDepartments`,
        method: "GET"
      })
    }),
    departments: build.query({
      query: () => ({
        url: `/employees/api/v1/departments`,
        method: "GET"
      })
    }),
    updateAllowedDepartments: build.mutation({
      query: ({ id, departmentsIds }) => ({
        url: `/employees/users/${id}/allowedDepartments`,
        method: "POST",
        body: departmentsIds
      })
    }),
    updateRole: build.mutation({
      query: ({ id, params }) => ({
        url: `/employees/users/${id}/role`,
        method: "PATCH",
        body: params
      })
    })
  })
});

export const {
  useNationalHolidaysQuery,
  useCreateNationalHolidaysMutation,
  useDeleteNationalHolidaysMutation,
  useCompanySitesQuery,
  useRolesQuery,
  useRoleByNameMutation,
  useRouteResourcesQuery,
  useCompanyStylesQuery,
  usePrefetch: prefetchAdminSettingsApi,
  useAllowedDepartmentsMutation,
  useDepartmentsQuery,
  useUpdateAllowedDepartmentsMutation,
  useUpdateRoleMutation
} = adminSettingsApiSlice;
