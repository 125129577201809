import { ListItemText, MenuItem, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useCallback, useMemo } from "react";

import { nonZero } from "shared/lib";

import useSelectLogic from "./hooks/useSelectLogic";
import {
  CheckIcon,
  Container,
  CustomSelect,
  HelperText,
  OutlineInput,
  menuStyleProps,
  themedStyles
} from "./style/selectStyle";

type SelectProps = {
  handleChange: (event: any) => void;
  values: any[];
  name?: string;
  placeholder?: string;
  isMultiple?: boolean;
  selectedValue?: any[];
  defaultValue?: any[];
  defaultLabel?: string;
  helperText?: string;
  error?: boolean;
  selectStyles?: object;
  containerStyles?: object;
  disabled?: boolean;
  readonly?: boolean;
  fullWidth?: boolean;
  displayEmpty?: boolean;
  required?: boolean;
  onClose?: () => void;
};

const Select = ({
  isMultiple,
  handleChange,
  placeholder,
  values,
  name,
  selectedValue = [],
  defaultValue = [],
  defaultLabel,
  helperText,
  error,
  disabled,
  readonly,
  selectStyles,
  onClose,
  fullWidth = true,
  displayEmpty = true,
  required = true,
  containerStyles,
  ...props
}: SelectProps) => {
  const theme = useTheme();
  const styles = themedStyles(theme);
  const { data, onClear } = useSelectLogic({ handleChange, selectedValue, defaultValue });

  const renderDefaultItem = useMemo(() => {
    if (!defaultLabel?.length) return null;

    const checked = data?.length === 0;

    return (
      <MenuItem
        onMouseDown={onClear}
        sx={checked ? styles.menuItemDefaultStyle : styles.menuItemCheckedStyle}
        disabled={readonly}
      >
        {checked ? <CheckIcon fontSize="medium" /> : null}
        <ListItemText primary={defaultLabel} />
      </MenuItem>
    );
  }, [defaultLabel, data, readonly, onClear, styles]);

  const renderDisplayValue = useCallback(
    (selected: any) => {
      if (!selected || selected?.length === 0) {
        return defaultLabel?.length ? (
          <Typography>{defaultLabel}</Typography>
        ) : (
          <Typography color="#BABEC5">{placeholder}</Typography>
        );
      }

      return selected
        .map((value: any) => {
          const item = values?.find((item: any) => item.value === value);

          return item?.option;
        })
        ?.join(", ");
    },
    [values, defaultLabel, placeholder]
  );

  const renderHelperText = useMemo(() => {
    if (!helperText?.length) return null;

    return (
      <HelperText>
        <Typography variant="caption">{helperText}</Typography>
      </HelperText>
    );
  }, [helperText]);

  const renderMenuItem = useCallback(
    ({ value, option, disabled }: any) => {
      if (nonZero(value) && nonZero(option)) return null;

      const checked = data?.length && data.findIndex((item: any) => item === value) !== -1;

      return (
        <MenuItem
          key={option}
          value={value || option}
          sx={checked ? styles.menuItemCheckedStyle : styles.menuItemDefaultStyle}
          disabled={readonly || disabled}
        >
          {checked ? <CheckIcon fontSize="medium" /> : null}

          <ListItemText primary={option} />
        </MenuItem>
      );
    },
    [data]
  );

  return (
    <Container
      sx={[helperText?.length ? styles.containerWithHelperStyles : {}, containerStyles ?? {}]}
      // @ts-ignore
      className={[disabled ? "select-disabled" : ""]}
    >
      <CustomSelect
        multiple={isMultiple}
        displayEmpty={displayEmpty}
        required={required}
        value={data || []}
        defaultValue={values || []}
        onChange={handleChange}
        fullWidth={fullWidth}
        disabled={disabled}
        onClose={onClose}
        name={name}
        sx={{ ...styles.selectStyles, ...selectStyles }}
        renderValue={renderDisplayValue}
        input={<OutlineInput label="" size="small" error={error} />}
        MenuProps={menuStyleProps}
        {...props}
      >
        {renderDefaultItem}

        {values?.map(item => renderMenuItem(item))}
      </CustomSelect>

      {renderHelperText}
    </Container>
  );
};

export default Select;
