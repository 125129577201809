import { useState } from "react";
import { useDispatch } from "react-redux";

import { prefetchAdminSettingsApi } from "features/adminSettings/adminSettingsApiSlice";
import { useLoginMutation } from "features/auth/authApiSlice";
import { setConfirmationCode } from "features/auth/authSlice";

import { RESEND_CODE_TYPE, ROUTE_CONFIRMATION_CODE, ROUTE_LOGIN } from "shared/constants";
import { isValidEmail } from "shared/lib/validation";

export default function useSignInPageLogic({ history }: { history: any }) {
  const [email, setEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [captcha, setCaptcha] = useState(false); // false

  const [login, { isLoading }] = useLoginMutation();
  const prefetchCompanyStyles = prefetchAdminSettingsApi("companyStyles");

  const dispatch = useDispatch();

  const handleChange = (e: any) => {
    if (errorMsg?.length) setErrorMsg("");

    const value = e.target.value;
    setEmail(value);
  };

  const handleCaptcha = (value: any) => {
    setCaptcha(Boolean(value));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      setErrorMsg("Incorrect email address");
      return;
    }
    setErrorMsg("");
    try {
      const result = await login({ email, notificationType: RESEND_CODE_TYPE.SMS }).unwrap();
      dispatch(setConfirmationCode({ confirmationCode: result.code, email }));
      prefetchCompanyStyles({ email });
      setEmail("");

      history.push(`/${ROUTE_LOGIN}/${ROUTE_CONFIRMATION_CODE}`);
    } catch (err: any) {
      if (err?.data) {
        setErrorMsg(err.data?.message);
      } else {
        setErrorMsg("Login Failed");
      }
    }
  };

  return {
    email,
    errorMsg,
    captcha,
    isLoading,
    handleChange,
    handleCaptcha,
    handleSubmit
  };
}
