import { isValidAndNotEmptyString } from "./validation";

/**
 *
 * @param {string} value
 * @returns
 */
export const capitalizeFirstLetter = value => {
  if (isValidAndNotEmptyString(value)) {
    const str = value.toLowerCase();

    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return "";
};

/**
 *
 * @param {string} text
 * @param {number} length
 * @returns
 */
export function textOverflowEllipsis(text, length) {
  if (text == null) {
    return "";
  }
  if (text.length <= length) {
    return text;
  }
  text = text.substring(0, length);

  return `${text}...`;
}

/**
 *
 * @param {string} value
 * @returns
 */
export const dashCaseToText = value => {
  if (!isValidAndNotEmptyString(value)) return "";

  return value.toLowerCase().split("_").join(" ");
};

/**
 *
 * @param {string} value
 * @returns
 */
export const convertStringToKebabCase = value => {
  if (isValidAndNotEmptyString(value)) {
    return value
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[\u0590-\u05FF]|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join("-");
  }

  return "";
};

/**
 *
 * @param {string} text
 * @returns {string}
 */
export function camelCaseToTitleCase(text) {
  if (!text?.length || typeof text !== "string") return "";

  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

/**
 *
 * @param {string} value
 * @returns {boolean | string}
 */
export function parseBooleanString(value) {
  if (value === "true") {
    return true;
  } else if (value === "false") {
    return false;
  } else {
    return value;
  }
}

export function parseFloatString(value) {
  const res = parseFloat(value);

  return isNaN(res) ? value : res;
}
