import moment from "moment";
import { useSelector } from "react-redux";

import { selectBuildingFilter, selectDepartmentsFilter, selectFloorsFilter } from "core/selectors";

import {
  useExportAttendanceMutation,
  useExportAvgDaysPerWeekMutation,
  useExportAvgEmployeesPerDayMutation,
  useExportDayTypeChangesMutation,
  useExportEmployeesPlanMutation,
  useExportWorkstationsPlanMutation
} from "features/attendance/attendanceApiSlice";

import { EXPORT_ATTENDANCE_TYPE, MOMENT_DATE_FORMAT } from "shared/constants";
import { useToggle } from "shared/hooks/useToggle";

const mapStateToProps = (state: any) => [
  selectBuildingFilter(state),
  selectFloorsFilter(state),
  selectDepartmentsFilter(state)
];

export default function useExportAttendance() {
  /* ------------------ STATE ------------------ */

  const [building, floors, departments] = useSelector(mapStateToProps);

  /* ------------------ HOOKs ------------------ */

  const exportFileSidebar = useToggle();

  /* ------------------ APIs ------------------ */

  const [exportAttendance, { isLoading: isLoadingAttendance, isSuccess: isSuccessAttendance, error: errorAttendance }] =
    useExportAttendanceMutation();

  const [
    exportAvgDaysPerWeek,
    { isLoading: isLoadingAvgDaysPerWeek, isSuccess: isSuccessAvgDaysPerWeek, error: errorAvgDaysPerWeek }
  ] = useExportAvgDaysPerWeekMutation();

  const [
    exportAvgEmployeesPerDay,
    { isLoading: isLoadingAvgEmployeesPerDay, isSuccess: isSuccessAvgEmployeesPerDay, error: errorAvgEmployeesPerDay }
  ] = useExportAvgEmployeesPerDayMutation();

  const [
    exportEmployeesPlan,
    { isLoading: isLoadingEmployeesPlan, isSuccess: isSuccessEmployeesPlan, error: errorEmployeesPlan }
  ] = useExportEmployeesPlanMutation();

  const [
    exportWorkstationsPlan,
    { isLoading: isLoadingWorkstationsPlan, isSuccess: isSuccessWorkstationsPlan, error: errorWorkstationsPlan }
  ] = useExportWorkstationsPlanMutation();

  const [
    exportDayTypeChanges,
    {
      isLoading: isLoadingExportDayTypeChanges,
      isSuccess: isSuccessExportDayTypeChanges,
      error: errorExportDayTypeChanges
    }
  ] = useExportDayTypeChangesMutation();

  /* ------------------ HANDLERs ------------------ */

  /**
   *
   * @param {EXPORT_ATTENDANCE_TYPE?} type - one of EXPORT_ATTENDANCE_TYPE
   * @param {*} param1
   */
  const handleExportFile = async ({ fileType, fromDate, toDate }: any, type?: any) => {
    const params = {
      fromDate: moment(fromDate).format(MOMENT_DATE_FORMAT),
      toDate: moment(toDate).format(MOMENT_DATE_FORMAT),
      fileType,
      building,
      floors,
      departments
    };

    if (!type) {
      exportAttendance(params);
    }

    if (type === EXPORT_ATTENDANCE_TYPE.daysPerWeek) {
      exportAvgDaysPerWeek(params);
    }

    if (type === EXPORT_ATTENDANCE_TYPE.employeesPerDay) {
      exportAvgEmployeesPerDay(params);
    }

    if (type === EXPORT_ATTENDANCE_TYPE.employeesPlan) {
      exportEmployeesPlan(params);
    }

    if (type === EXPORT_ATTENDANCE_TYPE.workstationsPlan) {
      exportWorkstationsPlan(params);
    }

    if (type === EXPORT_ATTENDANCE_TYPE.singleDay) {
      const { fromDate, toDate: _, ...rest } = params;

      exportDayTypeChanges({ date: fromDate, ...rest });
    }
  };

  return {
    exportFileSidebar,
    handleExportFile,
    isLoadingExportFile:
      isLoadingAttendance ||
      isLoadingAvgDaysPerWeek ||
      isLoadingAvgEmployeesPerDay ||
      isLoadingExportDayTypeChanges ||
      isLoadingEmployeesPlan ||
      isLoadingWorkstationsPlan,
    isSuccessExportFile:
      isSuccessAttendance ||
      isSuccessAvgDaysPerWeek ||
      isSuccessAvgEmployeesPerDay ||
      isSuccessExportDayTypeChanges ||
      isSuccessEmployeesPlan ||
      isSuccessWorkstationsPlan,
    errorExportFile:
      errorAttendance ||
      errorAvgDaysPerWeek ||
      errorAvgEmployeesPerDay ||
      errorExportDayTypeChanges ||
      errorEmployeesPlan ||
      errorWorkstationsPlan
  };
}
