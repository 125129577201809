import { Box, Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";

import BuildingIcon from "shared/assets/icons/building.svg";
import CalendarIcon from "shared/assets/icons/calendar.svg";
import DepartmentIcon from "shared/assets/icons/department.svg";
import EditIcon from "shared/assets/icons/edit.svg";
import FloorIcon from "shared/assets/icons/floor.svg";
import RoomIcon from "shared/assets/icons/room.svg";
import SiteIcon from "shared/assets/icons/web.svg";
import CustomizedDivider from "shared/components/CustomizedDivider/CustomizedDivider";
import CustomizedLabel from "shared/components/CustomizedLabel/CustomizedLabel";
import Sidebar from "shared/components/Sidebar/Sidebar";
import UserInfo from "shared/components/UserInfo/UserInfo";
import WeekPlan from "shared/components/WeekPlan";
import { getNumberWithOrdinal } from "shared/lib/getters";

import useUserInfoPageLogic, { WEEKLY_TAB } from "./hooks/useUserInfoPageLogic";
import {
  ActionContainer,
  CustomizedGridContainer,
  CustomizedIcon,
  EditButton,
  Icon,
  InfoContainer,
  styles
} from "./style/userInfoPageStyle";

type UserInfoPageProps = {
  history: any;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  userId: number;
  onEditUser: (id: number) => void;
};

const UserInfoPage = ({ history, isOpen, setIsOpen, userId, onEditUser }: UserInfoPageProps) => {
  const {
    handleSubmit,
    onSubmit,
    error,
    isSuccess,
    isLoading,
    user,
    weekPlanData,
    selectedIndex,
    setSelectedIndex,
    selectedRooms,
    handleChangeRoom,
    handleChangeDayType,
    handleApplySelection,
    onRemoveAssignmentClick,
    dayTypes,
    onEditClick,
    isLoadingWeekPlan,
    disableWeekPlan,
    permanentSeat,
    tab,
    onChangeTab,
    startWeek,
    endWeek
  } = useUserInfoPageLogic({
    userId,
    onEditUser,
    setIsOpen
  });

  return (
    <div>
      {/* @ts-ignore */}
      <Sidebar
        history={history}
        onResolve={handleSubmit(onSubmit)}
        resolveLabel="OK"
        isDrawer
        shouldValidate
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        error={error}
        isSuccess={isSuccess}
        isLoading={isLoading}
      >
        <Box>
          <Typography variant="h4" fontWeight="700">
            User information
          </Typography>
        </Box>
        <Box mt={2}>
          <UserInfo
            phone={user?.phone}
            avatar={user?.image}
            floor={user?.title}
            email={user?.email}
            firstName={user?.firstName}
            lastName={user?.lastName}
            status={user?.status === "arrived" ? user.status : ""}
          />
        </Box>
        <CustomizedDivider bottomWidth="2px" hrColor="#8D9096" />
        <Box mt={2} mb={4}>
          {/* @ts-ignore */}
          <CustomizedLabel text="User info" variant="h5" />
          <CustomizedGridContainer container mt={2}>
            <Grid item xs={5}>
              <InfoContainer>
                {/* @ts-ignore */}
                <Icon src={SiteIcon} />
                <Typography>{user?.site}</Typography>
              </InfoContainer>
              {/* @ts-ignore */}
              <CustomizedDivider />
            </Grid>
            <Grid item xs={5}>
              <InfoContainer>
                {/* @ts-ignore */}
                <Icon src={BuildingIcon} />
                <Typography>{user?.building}</Typography>
              </InfoContainer>
              {/* @ts-ignore */}
              <CustomizedDivider />
            </Grid>
          </CustomizedGridContainer>
          <CustomizedGridContainer container mt={2}>
            <Grid item xs={5}>
              <InfoContainer>
                {/* @ts-ignore */}
                <Icon src={FloorIcon} />
                {user?.floor ? <Typography>{getNumberWithOrdinal(user?.floor)} Floor</Typography> : "None"}
              </InfoContainer>
              {/* @ts-ignore */}
              <CustomizedDivider />
            </Grid>
            <Grid item xs={5}>
              <InfoContainer>
                {/* @ts-ignore */}
                <Icon src={DepartmentIcon} />
                <Typography>{user?.department}</Typography>
              </InfoContainer>
              {/* @ts-ignore */}
              <CustomizedDivider />
            </Grid>
          </CustomizedGridContainer>
          <CustomizedGridContainer container mt={2}>
            <Grid item xs={5}>
              <InfoContainer>
                {/* @ts-ignore */}
                <Icon src={RoomIcon} />
                <Typography>{permanentSeat}</Typography>
              </InfoContainer>
            </Grid>
            <Grid item xs={5}>
              <InfoContainer>
                {/* @ts-ignore */}
                <Icon src={CalendarIcon} />
                <Typography>{user?.officeDays} office days per week</Typography>
              </InfoContainer>
            </Grid>
          </CustomizedGridContainer>
        </Box>
        <CustomizedDivider bottomWidth="2px" hrColor="#8D9096" />
        <Box mt={2}>
          {/* @ts-ignore */}
          <CustomizedLabel text="Weekly plan" variant="h5" />

          <Box sx={styles.tabsContainerStyle}>
            <Tabs
              value={tab}
              onChange={onChangeTab}
              textColor="secondary"
              indicatorColor="secondary"
              sx={styles.tabsStyle}
            >
              <Tab
                value={WEEKLY_TAB.thisWeek}
                label={`This week, ${format(startWeek, "dd")} - ${format(endWeek, "dd/MM/yyyy")}`}
                sx={styles.tabItemStyle}
              />
            </Tabs>
          </Box>

          <Grid container my={2} justifyContent="space-between" position="relative">
            {weekPlanData?.length || isLoadingWeekPlan ? (
              weekPlanData.map((item, index: number) => {
                const isLastIndex = weekPlanData.length - 1 === index;

                return (
                  <React.Fragment key={index}>
                    <Grid item xs={2.3}>
                      <WeekPlan
                        details={item}
                        index={index?.toString()}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        selectedRooms={selectedRooms}
                        handleChangeRoom={handleChangeRoom}
                        handleChangeDayType={handleChangeDayType}
                        handleApplySelection={handleApplySelection}
                        onRemoveAssignmentClick={onRemoveAssignmentClick}
                        dayTypes={dayTypes}
                        isLoading={isLoadingWeekPlan}
                        disabled={disableWeekPlan}
                      />
                    </Grid>
                    {!isLastIndex && (
                      <Divider orientation="vertical" flexItem variant="middle" sx={{ margin: "16px 0" }} />
                    )}
                  </React.Fragment>
                );
              })
            ) : (
              <Typography variant="h6" color="#6B6C72" textAlign={"center"} pb={3}>
                No weekly for next week generated, yet.
              </Typography>
            )}
          </Grid>
          {/* @ts-ignore */}
          <ActionContainer selectedIndex={selectedIndex}>
            <CustomizedDivider bottomWidth="2px" hrColor="#8D9096" />
            {/* @ts-ignore */}
            <CustomizedLabel text="Actions" variant="h5" />
            <Box display="flex" justifyContent="center">
              <EditButton onClick={onEditClick}>
                {/* @ts-ignore */}
                <CustomizedIcon src={EditIcon} />
                <Typography>Edit info</Typography>
              </EditButton>
            </Box>
          </ActionContainer>
        </Box>
      </Sidebar>
    </div>
  );
};

export default UserInfoPage;
