import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { withRouter } from "react-router-dom";

import DataTable from "shared/components/DataTable/DataTable";
import ConfirmDialog from "shared/components/Dialogs/ConfirmDialog";
import PageTitle from "shared/components/PageTitle";
import { NEIGHBORHOODS_PAGE_NAME } from "shared/constants";

import AdminSettingsHolidaySidebar from "./components/AdminSettingsHolidaySidebar";
import AdminSettingsNeighborhoodsSidebar from "./components/AdminSettingsNeighborhoodsSidebar/AdminSettingsNeighborhoodsSidebar";
import AdminSettingsUserSidebar from "./components/AdminSettingsUserSidebar";
import useAdminSettingsHolidays from "./hooks/useAdminSettingsHolidays";
import useAdminSettingsNeighborhoods from "./hooks/useAdminSettingsNeighborhoods";
import useAdminSettingsUsers from "./hooks/useAdminSettingsUsers";
import { BodyBox, ContainerBox } from "./style/adminSettingsPageStyle";
import { columnsHolidays, deleteHolidayModalContent } from "./utils/adminSettingsPageHolidayDictionary";
import {
  columnsUsers,
  deleteNeighborhoodContentText,
  deleteNeighborhoodTitleText,
  deleteUserModalContent,
  neighborhoodColumns
} from "./utils/adminSettingsPageUserDictionary";

const AdminSettingsListingPage = () => {
  const { listAdmins, onEditUser, onDeleteUser, onAddUser, isUserLoading, userSidebar, userId, onCloseUserSidebar } =
    useAdminSettingsUsers();

  const {
    listHolidays,
    onInfoHoliday,
    onDeleteHoliday,
    onAddHoliday,
    isHolidaysLoading,
    holidaySidebar,
    holidayId,
    deleteHolidayDialog,
    onSidebarDeleteHoliday
  } = useAdminSettingsHolidays();

  const { list, onEdit, onDelete, onAdd, isLoading, neighborhoodsSidebar, neighborhoodName, onClose } =
    useAdminSettingsNeighborhoods();

  const renderSidebars = useMemo(
    () => (
      <>
        <AdminSettingsUserSidebar isOpen={userSidebar.enable} onClose={onCloseUserSidebar} userId={userId} />
        <AdminSettingsHolidaySidebar
          isOpen={holidaySidebar.enable}
          onClose={holidaySidebar.set}
          isInfo={!!holidayId}
          holidayId={holidayId}
          onDelete={onSidebarDeleteHoliday}
        />
        <AdminSettingsNeighborhoodsSidebar
          isOpen={neighborhoodsSidebar.enable}
          onClose={onClose}
          neighborhoodName={neighborhoodName}
        />
      </>
    ),
    [holidaySidebar, holidayId, userSidebar, userId, onSidebarDeleteHoliday, onCloseUserSidebar]
  );

  const renderDialogs = useMemo(
    () => (
      <>
        <ConfirmDialog
          showDialog={deleteHolidayDialog.enable}
          resolve={() => onDeleteHoliday(holidayId!)}
          reject={deleteHolidayDialog.off}
          acceptLabel={"OK"}
          title={deleteHolidayModalContent.title}
          text={deleteHolidayModalContent.body}
          rowName={null}
          rowId={null}
          setShowDialog={deleteHolidayDialog.off}
        />
      </>
    ),
    [deleteHolidayDialog, onDeleteHoliday, holidayId]
  );

  return (
    <ContainerBox>
      <PageTitle title="Admin Settings" />

      <BodyBox>
        <Grid container rowSpacing={1} spacing={2} mt={2}>
          <Grid item xs={6} flexGrow={1}>
            <DataTable
              rows={listAdmins}
              columns={columnsUsers}
              tableName="Dashboard Users"
              circleAdd={true}
              onEdit={onEditUser}
              onDelete={onDeleteUser}
              onAdd={onAddUser}
              deleteTitle={deleteUserModalContent.title}
              deleteContentText={deleteUserModalContent.body}
              deleteWarningText={deleteUserModalContent.warningText}
              showInfoColumn={false}
              isLoading={isUserLoading}
              containerStyle={{ height: "100%" }}
            />
          </Grid>
          <Grid item xs={6} flexGrow={1}>
            <DataTable
              rows={listHolidays}
              columns={columnsHolidays}
              tableName="National Holidays"
              circleAdd={true}
              onInfo={onInfoHoliday}
              onDelete={onDeleteHoliday}
              onAdd={onAddHoliday}
              deleteTitle={deleteHolidayModalContent.title}
              deleteContentText={deleteHolidayModalContent.body}
              isLoading={isHolidaysLoading}
              showEditColumn={false}
              containerStyle={{ height: "100%" }}
            />
          </Grid>
        </Grid>

        <Grid container rowSpacing={1} spacing={2} mt={2}>
          <Grid item xs={6} flexGrow={1}>
            <DataTable
              rows={list}
              columns={neighborhoodColumns}
              tableName={NEIGHBORHOODS_PAGE_NAME}
              circleAdd={true}
              onEdit={onEdit}
              onDelete={onDelete}
              onAdd={onAdd}
              deleteTitle={deleteNeighborhoodTitleText}
              deleteContentText={deleteNeighborhoodContentText}
              showSiteAndBuilding
              showInfoColumn={false}
              isLoading={isLoading}
              containerStyle={{ height: "100%" }}
            />
          </Grid>

          <Grid item xs={6} flexGrow={1} />
        </Grid>
      </BodyBox>

      {renderSidebars}
      {renderDialogs}
    </ContainerBox>
  );
};

AdminSettingsListingPage.propTypes = {
  history: PropTypes.object,
  path: PropTypes.string
};

export default withRouter(AdminSettingsListingPage);
