export const upsert = ({
  array,
  setState,
  element,
  reason
}: {
  array: any[];
  setState: React.Dispatch<React.SetStateAction<any[]>>;
  element: any;
  reason?: string;
}) => {
  const index = array.findIndex(item => item.date === element.date);

  if (reason === "clear") {
    setState(prevState => prevState.filter((item: any) => item.date !== element.date));
    return;
  }

  if (index > -1) {
    const newArr = [...array];
    newArr[index] = element;
    setState(newArr);
    return;
  }

  setState([...array, element]);
};
