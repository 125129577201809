import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { selectAccessToken, selectCToken, selectSiteFilter, selectSiteId, selectUserEmail } from "core/selectors";

import {
  useGetGroupsQuery,
  useGetSiteEventsQuery,
  useSendReminderMutation
} from "features/activities/activitiesApiSlice";
import { useGetEmployeeDataQuery } from "features/profile/profileApiSlice";
import { setEmployeeData } from "features/profile/profileSlice";
import { useGetDepartmentsListQuery } from "features/userManagement/userManagementApiSlice";
import { setDepartmentsList } from "features/userManagement/userManagementSlice";

import { POLING_INTERVAL } from "shared/constants";
import { useToggle } from "shared/hooks/useToggle";

const fromD = new Date();
const toD = new Date();
const fromDate = new Date(fromD.setFullYear(fromD.getFullYear() - 1)).toISOString();
const toDate = new Date(toD.setFullYear(toD.getFullYear() + 1)).toISOString();

export default function useActivitiesLogic() {
  /* ------------------ HOOKs ------------------ */

  const dispatch = useDispatch();
  const siteAndBuildingSidebar = useToggle();
  const groupsSidebar = useToggle();
  const viewGroupSidebar = useToggle();
  const eventSidebar = useToggle();
  const sessionSidebar = useToggle();
  const sendReminderDialog = useToggle();

  /* ------------------ SELECTORs ------------------ */

  const site = useSelector(selectSiteFilter);
  const token = useSelector(selectAccessToken);
  const email = useSelector(selectUserEmail);
  const siteId = useSelector(selectSiteId);
  const cToken = useSelector(selectCToken);

  /* ------------------ STATEs ------------------ */

  // Groups
  const [group, setGroup] = useState({});
  const [isEditGroup, setIsEditGroup] = useState(false);
  // Events
  const [siteEvent, setSiteEvent] = useState({});
  const [isEditEvent, setIsEditEvent] = useState(false);
  const [reminderId, setReminderId] = useState({});
  // Sessions
  const [isEditSession, setIsEditSession] = useState(false);
  const [session, setSession] = useState({});

  /* ------------------ APIs ------------------ */

  const { data: groups } = useGetGroupsQuery({ siteId }, { skip: !site || !siteId, pollingInterval: POLING_INTERVAL });
  const { data: events } = useGetSiteEventsQuery(
    { site, from: fromDate, to: toDate },
    { skip: !site, pollingInterval: POLING_INTERVAL }
  );
  const { data: departmentsData, isLoading: isDepartmentsLoading } = useGetDepartmentsListQuery(undefined, {
    skip: !token
  });
  const { data: employeeData, isLoading: isLoadingEmployeeData } = useGetEmployeeDataQuery(
    { email, cToken },
    { skip: !email?.length }
  );
  const [sendReminder] = useSendReminderMutation();

  /* ------------------ HANDLERs ------------------ */

  const sendReminderHandler = async () => {
    try {
      await sendReminder({ id: reminderId }).unwrap();

      toast.success("Done.");
    } catch (error) {
      console.error("ERROR: sendReminderHandler :", { error });
    }
  };

  /* ------------------ MEMOs ------------------ */

  const eventsSidebarProps = useMemo(
    () => ({
      isOpen: eventSidebar.enable,
      setIsOpen: eventSidebar.set,
      isEdit: isEditEvent,
      event: siteEvent
    }),
    [eventSidebar, isEditEvent, siteEvent]
  );

  const viewGroupSidebarProps = useMemo(
    () => ({
      isOpen: viewGroupSidebar.enable,
      setIsOpen: viewGroupSidebar.set,
      group,
      onEditGroup,
      onEditSession,
      onAddSession,
      onViewSession
    }),
    [viewGroupSidebar, group, onEditGroup, onEditSession, onAddSession, onViewSession]
  );

  const groupSidebarProps = useMemo(
    () => ({
      isOpen: groupsSidebar.enable,
      setIsOpen: groupsSidebar.set,
      isEdit: isEditGroup,
      group
    }),
    [groupsSidebar, group, isEditGroup]
  );

  const sessionSidebarProps = useMemo(
    () => ({
      isOpen: sessionSidebar.enable,
      setIsOpen: sessionSidebar.set,
      isEdit: isEditSession,
      group,
      session,
      onEditSession
    }),
    [sessionSidebar, group, isEditGroup, session, onEditSession]
  );

  /* ------------------ EFFECTs ------------------ */

  useEffect(() => {
    if (departmentsData && !isDepartmentsLoading) {
      dispatch(setDepartmentsList(departmentsData.departments));
    }
  }, [departmentsData, isDepartmentsLoading]);

  useEffect(() => {
    if (!isLoadingEmployeeData && employeeData) {
      dispatch(setEmployeeData(employeeData));
    }
  }, [employeeData, isLoadingEmployeeData]);

  /* ------------------ EVENT HANDLERs ------------------ */

  // groups
  const onGroupClick = (group: any) => {
    viewGroupSidebar.on();
    setGroup(group);
  };

  const onAddGroup = () => {
    groupsSidebar.on();
    setIsEditGroup(false);
    setGroup({});
  };

  function onEditGroup(group: any) {
    groupsSidebar.on();
    setIsEditGroup(true);
    setGroup(group);
  }

  // sessions
  function onAddSession(group: any) {
    sessionSidebar.on();
    setIsEditSession(false);
    setGroup(group);
    setSession({});
  }

  function onViewSession(activeSession: any) {
    setSession(activeSession);
    setIsEditSession(false);
    sessionSidebar.on();
  }

  function onEditSession(activeSession: any) {
    setSession(activeSession);
    setIsEditSession(true);
    sessionSidebar.on();
  }

  // Events
  const onAddEventClick = (event: any) => {
    setSiteEvent(event);
    setIsEditEvent(false);
    eventSidebar.on();
  };

  const onEventClick = (event: any) => {
    setSiteEvent(event);
    setIsEditEvent(true);
    eventSidebar.on();
  };

  const onPressSendReminder = (id: string) => {
    setReminderId(id);
    sendReminderDialog.on();
  };

  return {
    groups,
    events,
    siteAndBuildingSidebar,
    eventsSidebarProps,
    viewGroupSidebarProps,
    groupSidebarProps,
    sessionSidebarProps,
    onAddGroup,
    onGroupClick,
    onAddSession,
    onAddEventClick,
    onEventClick,
    sendReminderDialog,
    onPressSendReminder,
    sendReminderHandler
  };
}
