import Button from "@mui/material/Button";
import styled from "@mui/material/styles/styled";
import PropTypes from "prop-types";
import React from "react";

const ResolveButton = ({ variant = "contained", text, onClick, disabled, autoFocus }) => {
  return (
    <CustomizedButton variant={variant} onClick={onClick} disabled={disabled} autoFocus={autoFocus}>
      {text}
    </CustomizedButton>
  );
};

const CustomizedButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(2.25),
  padding: theme.spacing(0.5, 4),
  color: "#fff",
  fontWeight: "700",
  margin: theme.spacing(0, 1),
  minWidth: "113px"
}));

ResolveButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool
};

export default ResolveButton;
