import { format } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import {
  selectAccessToken,
  selectBuildingFilter,
  selectFilteredUserManagementList,
  selectSiteFilter
} from "core/selectors";

import {
  useAssignDailySeatMutation,
  useRemoveDailySeatMutation,
  useWeeklyPlanMutation,
  useWorkstationByIdsMutation,
  useWorkstationsByBuildingsQuery
} from "features/workstations/workstationApiSlice";

import useWorkWeekDates from "shared/hooks/useWorkWeekDates";
import { getDataEntityItemById } from "shared/lib/getters";
import { capitalizeFirstLetter } from "shared/lib/stringsLib";
import { upsert } from "shared/lib/upsert";
import { isEmptyObject } from "shared/lib/validation";

type UserInfoPageProps = {
  userId: number;
  onEditUser: (id: number) => void;
  setIsOpen: (isOpen: boolean) => void;
};

const defaultValues = {
  dailyRoom: "",
  dayType: ""
};

export const WEEKLY_TAB = {
  thisWeek: 1,
  nextWeek: 2
};

const mapStateToProps = (state: any) => ({
  userManagementList: selectFilteredUserManagementList(state),
  site: selectSiteFilter(state),
  building: selectBuildingFilter(state),
  token: selectAccessToken(state)
});

export default function useUserInfoPageLogic({ userId, onEditUser, setIsOpen }: UserInfoPageProps) {
  /* ------------------ HOOKs ------------------ */

  const { handleSubmit } = useForm({ mode: "onChange", defaultValues });
  const { startWeek, endWeek } = useWorkWeekDates();

  /* ------------------ STATEs ------------------ */

  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedRooms, setSelectedRooms] = useState<any[]>([]);
  const [dayTypes, setDayTypes] = useState<any[]>([]);
  const [isCloseOnResolve, setCloseOnResolve] = useState(true);
  const [weekPlanData, setWeekPlanData] = useState<any[]>([]);
  const [tab, setTab] = useState(WEEKLY_TAB.thisWeek);
  const { userManagementList, site, token, building } = useSelector(mapStateToProps);

  /* ------------------ APIs ------------------ */

  const { data: workstationByBuildingsData, isLoading: isWorkstationByBuildingsLoading } =
    useWorkstationsByBuildingsQuery(
      { site, building, date: format(new Date(), "yyyy-MM-dd") },
      { skip: !token || !site || !building }
    );

  const [weeklyPlan, { data: weeklyPlanData, isLoading: isWeeklyDataLoading }] = useWeeklyPlanMutation();

  const [assignDailySeat, { isLoading: isAssignLoading, error: assignError, isSuccess: isSuccessAssigned }] =
    useAssignDailySeatMutation();
  const [removeDailySeat, { isLoading: isRemoveLoading, error: removeError }] = useRemoveDailySeatMutation();
  const [workstationByIds, { error: errorWorkstationByIds, isLoading: isLoadingWorkstationsByIds }] =
    useWorkstationByIdsMutation();

  /* ------------------ MEMOs ------------------ */

  const user = useMemo(() => getDataEntityItemById(userManagementList, userId), [userManagementList, userId]);
  const permanentSeat = useMemo(() => {
    if (
      !user?.workstationId ||
      isWorkstationByBuildingsLoading ||
      isEmptyObject(workstationByBuildingsData) ||
      !building ||
      !workstationByBuildingsData[building]?.length
    )
      return "No room assigned";

    const seat = workstationByBuildingsData[building].find((item: any) => item.workstationId === user.workstationId);

    return seat?.workstationName ? `Room ${seat.workstationName}` : "No room assigned";
  }, [user?.workstationId, isWorkstationByBuildingsLoading, workstationByBuildingsData, building]);

  /* ------------------ HANDLERs ------------------ */

  const weekPlanHandler = async () => {
    const ids = weeklyPlanData?.filter((item: any) => item.seatId).map((item: any) => item.seatId);

    if (!ids?.length) {
      setWeekPlanData(weeklyPlanData.map((item: any) => ({ ...item, workstationName: "" })));
    } else {
      try {
        const workstationsDetails = await workstationByIds({ ids }).unwrap();
        const mapped = weeklyPlanData.map((day: any) => {
          const details = workstationsDetails.find((item: any) => item.workstationId === day.seatId);

          return { ...day, workstationName: details?.workstationName };
        });

        setWeekPlanData(mapped);
      } catch (error) {
        console.error("ERROR:: weekPlanHandler :: ", { error });
      }
    }
  };

  const weeklyPlanByTabHandler = useCallback(
    (tab: any) => {
      weeklyPlan({
        employeeId: userId.toString(),
        from: format(startWeek, "yyyy-MM-dd"),
        to: format(endWeek, "yyyy-MM-dd")
      });
    },
    [weeklyPlan, userId]
  );

  const fetchData = async (payload: any) => {
    try {
      await assignDailySeat(payload);
    } catch (e) {
      console.error("ERROR", e);
    }
  };

  function getAllData(URLs: any) {
    return Promise.allSettled(URLs.map(fetchData));
  }

  /* ------------------ EFFECTs ------------------ */

  useEffect(() => {
    weeklyPlanByTabHandler(tab);
  }, [tab]);

  useEffect(() => {
    if (weeklyPlanData && !isWeeklyDataLoading) {
      weekPlanHandler();
    }
  }, [weeklyPlanData, isWeeklyDataLoading]);

  useEffect(() => {
    const mappedDayTypes: any = weekPlanData.map((day: any) => {
      const data = { type: day.dayType, date: day.date };
      return data;
    });
    setDayTypes(mappedDayTypes);
  }, [weekPlanData]);

  /* ------------------ EVENT HANDLERs ------------------ */

  const onEditClick = () => {
    onEditUser && onEditUser(userId);
  };

  const onRemoveAssignmentClick = async (date: any) => {
    try {
      await removeDailySeat({ date, employeeId: userId });
      weeklyPlanByTabHandler(tab);
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmit = () => {
    setCloseOnResolve(true);

    if (selectedRooms.length) {
      const mappedData = selectedRooms.map((room: any) => {
        return { date: room?.date, seatId: room?.item?.id, employeeId: userId, dayType: room?.dayType };
      });
      getAllData(mappedData);
    } else {
      setIsOpen(false);
    }
  };

  const onChangeTab = (_event: any, newTab: any) => {
    setTab(newTab);
  };

  const handleChangeRoom = (item: any, date: any, reason: any, dayType: any) => {
    const data = { item, date, dayType };
    upsert({ array: selectedRooms, element: data, setState: setSelectedRooms, reason });
  };

  const handleChangeDayType = (event: any, date: any) => {
    setCloseOnResolve(false);

    const {
      target: { value }
    } = event;

    const data = { type: value, date };
    upsert({ array: dayTypes, element: data, setState: setDayTypes });
  };

  const handleApplySelection = async (workstation: any, date: any, dayType: any) => {
    try {
      setCloseOnResolve(false);

      if (workstation?.id) {
        handleChangeRoom(workstation, date, "clear", dayType);
      }

      try {
        await assignDailySeat({
          date,
          employeeId: userId,
          dayType,
          seatId: workstation?.id
        });
      } catch (e) {
        console.error("ERROR", e);
      }
      weeklyPlanByTabHandler(tab);
    } catch (e) {
      console.error(e);
    }
  };

  return {
    handleSubmit,
    onSubmit,
    error: assignError || removeError || errorWorkstationByIds,
    isSuccess: isSuccessAssigned && isCloseOnResolve,
    isLoading: isAssignLoading || isRemoveLoading,
    isLoadingWeekPlan: isWeeklyDataLoading || isAssignLoading || isLoadingWorkstationsByIds,
    disableWeekPlan: isAssignLoading,
    user,
    weekPlanData,
    selectedIndex,
    setSelectedIndex,
    selectedRooms,
    handleChangeRoom,
    handleChangeDayType,
    handleApplySelection,
    onRemoveAssignmentClick,
    dayTypes,
    onEditClick,
    permanentSeat,
    tab,
    onChangeTab,
    startWeek,
    endWeek
  };
}
