import { createSlice } from "@reduxjs/toolkit";

import { STORAGE_COMPANY_SETTINGS } from "shared/constants";
import { parseBooleanString, parseFloatString } from "shared/lib";

import { profileApiSlice } from "./profileApiSlice";

const initialState = {
  employeeData: {},
  siteAndCompanyData: {
    companyId: 0,
    companyName: "",
    siteId: 0,
    siteName: "",
    timezone: ""
  },
  companyAndSiteSettings: {
    birthday_email_cc: "",
    birthday_email_sender: "",
    company_admin: "",
    company_app_theme: "",
    company_logo: "",
    daily_site_capacity: 0,
    default_days_in_office: 0,
    distance_to_office_in_meters: 0,
    dynamic_seating: false,
    email_sender_note: "",
    facilities_helpdesk_address: "",
    filter_categories: "",
    first_day_of_week: "",
    interaction_social_types: "",
    interaction_types: "",
    is_birthday_email_enabled: false,
    is_room_map_enabled: false,
    latitude: 0,
    locale: "",
    longitude: 0,
    mailing_list_changes: "",
    max_employees_in_site: 0,
    mobile_employee_filter_categorites: "",
    mobile_seat_filter_categorites: "",
    new_entrance_employee_email_recipients_ids: 0,
    office_wifi_name: "",
    seat_categories: "",
    timezone: "",
    web_seat_filter_categorites: ""
  }
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setEmployeeData: (state, action) => {
      state.employeeData = action.payload;
    },
    setSiteAndCompanyData: (state, action) => {
      state.siteAndCompanyData = action.payload;
    },
    setCompanySiteSettings: (state, action) => {
      state.companyAndSiteSettings = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addMatcher(profileApiSlice.endpoints.companySiteSettings.matchFulfilled, (state, action) => {
      const parsedSettings: Record<string, any> = {};

      Object.keys(action.payload).forEach(key => {
        const value = action.payload[key];

        parsedSettings[key] = parseBooleanString(parseFloatString(value as string));
      });

      localStorage.setItem(STORAGE_COMPANY_SETTINGS, JSON.stringify(parsedSettings));

      state.companyAndSiteSettings = parsedSettings as any;
    });
  }
});

export const { setEmployeeData, setSiteAndCompanyData, setCompanySiteSettings } = profileSlice.actions;
export default profileSlice.reducer;
