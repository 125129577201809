import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { selectSiteId } from "core/selectors";

import {
  usePersonalInterestAddMutation,
  usePersonalInterestDeleteMutation,
  usePersonalInterestsBySiteIdQuery,
  useProfessionalInterestAddMutation,
  useProfessionalInterestDeleteMutation,
  useProfessionalInterestsBySiteIdQuery,
  useUsersPersonalInterestsBySiteIdQuery
} from "features/interests/interestsApiSlice";

import { POLING_INTERVAL } from "shared/constants";
import { useToggle } from "shared/hooks/useToggle";

export default function useInterestsLogic() {
  const interestsSidebar = useToggle();

  /* ------------------ SELECTORs ------------------ */

  const sideId = useSelector(selectSiteId);

  /* ------------------ STATEs ------------------ */

  const [isPersonalInterests, setIsPersonalInterests] = useState(false);

  /* ------------------ APIs ------------------ */

  const { data: personalInterestsData } = usePersonalInterestsBySiteIdQuery(
    { id: sideId },
    { skip: !sideId, pollingInterval: POLING_INTERVAL }
  );
  const { data: professionalInterestsData } = useProfessionalInterestsBySiteIdQuery(
    { id: sideId },
    { skip: !sideId, pollingInterval: POLING_INTERVAL }
  );
  const { data: usersInterestsData } = useUsersPersonalInterestsBySiteIdQuery(
    { id: sideId },
    { skip: !sideId, pollingInterval: POLING_INTERVAL }
  );
  const [personalInterestAdd] = usePersonalInterestAddMutation();
  const [personalInterestDelete] = usePersonalInterestDeleteMutation();
  const [professionalInterestAdd] = useProfessionalInterestAddMutation();
  const [professionalInterestDelete] = useProfessionalInterestDeleteMutation();

  /* ------------------ HANDLERs ------------------ */

  const collectChartDataHandler = (interests: any, labelKey = "name", dataKey = "employeeIds") => {
    if (!interests?.length) return [];

    const labels: string[] = [];
    const data: number[] = [];
    const sortedABC = [...interests].sort((a, b) => {
      const valA = a ? a[dataKey]?.length : 0;
      const valB = b ? b[dataKey]?.length : 0;

      return valA < valB ? 1 : valB < valA ? -1 : 0;
    });
    const sliced = sortedABC.length > 10 ? sortedABC.slice(0, 9) : sortedABC;

    sliced.forEach(item => {
      labels.push(item[labelKey]);
      data.push(item[dataKey]?.length);
    });

    return { labels, data };
  };

  const addPersonalInterestHandler = (name: string) => personalInterestAdd({ id: sideId, name });
  const deletePersonalInterestHandler = (name: string) => personalInterestDelete({ id: sideId, name });
  const addProfessionalInterestHandler = (name: string) => professionalInterestAdd({ id: sideId, name });
  const deleteProfessionalInterestHandler = (name: string) => professionalInterestDelete({ id: sideId, name });

  /* ------------------ MEMOs ------------------ */

  const interestsSidebarProps = useMemo(
    () => ({
      isOpen: interestsSidebar.enable,
      setIsOpen: interestsSidebar.set,
      isPersonal: isPersonalInterests,
      list: isPersonalInterests ? personalInterestsData : professionalInterestsData,
      onAdd: isPersonalInterests ? addPersonalInterestHandler : addProfessionalInterestHandler,
      onDelete: isPersonalInterests ? addProfessionalInterestHandler : deleteProfessionalInterestHandler
    }),
    [
      interestsSidebar,
      isPersonalInterests,
      personalInterestsData,
      professionalInterestsData,
      addPersonalInterestHandler,
      deletePersonalInterestHandler,
      addProfessionalInterestHandler,
      deleteProfessionalInterestHandler
    ]
  );

  const personalInterestsChartData = useMemo(
    () => collectChartDataHandler(personalInterestsData),
    [personalInterestsData]
  );

  const personalInterestsNames = useMemo(
    () => (personalInterestsData?.length ? personalInterestsData.map((item: any) => item.name) : []),
    [personalInterestsData]
  );

  const professionalInterestsChartData = useMemo(
    () => collectChartDataHandler(professionalInterestsData),
    [professionalInterestsData]
  );

  const usersInterests = useMemo(
    () => (usersInterestsData?.length ? usersInterestsData : [usersInterestsData]),
    [professionalInterestsData]
  );

  /* ------------------ EVENT HANDLERs ------------------ */

  const onPersonalInterests = () => {
    setIsPersonalInterests(true);
    interestsSidebar.on();
  };
  const onProfessionalInterests = () => {
    setIsPersonalInterests(false);
    interestsSidebar.on();
  };

  return {
    onPersonalInterests,
    onProfessionalInterests,
    interestsSidebarProps,
    personalInterestsChartData,
    professionalInterestsChartData,
    usersInterests,
    personalInterestsNames,
    onPressUserInterest: addPersonalInterestHandler
  };
}
