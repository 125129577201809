import { useEffect } from "react";
import { useForm } from "react-hook-form";

import { EFileTypes } from "shared/constants/optionsConstants";

export interface ExportFileSidebarLogicProps {
  startDate?: string;
  endDate?: string;
  onExport?: (data: { fileType: string[]; fromDate: Date; toDate: Date }, type?: string) => void;
}

interface FormValues {
  startDate: Date;
  endDate: Date;
}

const fileType = EFileTypes[0].value;

export default function useExportFileSidebarLogic({ startDate, endDate, onExport }: ExportFileSidebarLogicProps) {
  /* ------------------ HOOKs ------------------ */

  const { control, watch, setValue } = useForm<FormValues>({
    defaultValues: {
      startDate: new Date(),
      endDate: new Date()
    }
  });

  /* ------------------ STATEs ------------------ */

  const [fromDate, toDate] = watch(["startDate", "endDate"]);

  /* ------------------ EFFECTs ------------------ */

  useEffect(() => {
    if (!startDate || !endDate) return;

    setValue("startDate", new Date(startDate));
    setValue("endDate", new Date(endDate));
  }, [startDate, endDate, setValue]);

  /* ------------------ EVENT HANDLERs ------------------ */

  const onExportHistory = (type?: string) => () => {
    onExport?.({ fileType: [fileType], fromDate, toDate }, type);
  };

  return {
    onExportHistory,
    control
  };
}
