import parse from "date-fns/parse";
import moment from "moment";

import { getWorkstationStatus } from "./getters";
import { isEmptyObject, isValidAndNotEmptyString } from "./validation";

export const setFormValues = ({
  data,
  setValue,
  usersByBuildingOptions,
  workstationList,
  neighborhoodList,
  roomsList,
  titleList,
  cityList
}) => {
  if (!isEmptyObject(data)) {
    Object.entries(data).forEach(([name, value]) => {
      if (name === "managerId" || name === "ownerId") {
        const owner = usersByBuildingOptions.find(item => item.id === value);
        if (value === 0 && !owner) {
          return setValue(name, { id: 0, value: "None" });
        }
        return setValue(name, owner);
      }

      if (name === "workstationId") {
        const seat = workstationList?.find(item => item.workstationId === value);

        if (seat) {
          const status = getWorkstationStatus(seat, "");
          const value = status ? `${seat.workstationName} - ${status.toLowerCase()}` : seat.workstationName;

          const mappedSeat = {
            id: seat.workstationId,
            option: seat.workstationId,
            value,
            assignedEmployeeId: seat.assignedEmployeeId,
            room: seat.roomName,
            disabled: !!status,
            floor: seat?.floor
          };

          return setValue("workstationId", mappedSeat);
        }
      }

      if (name === "dateOfBirth" || name === "employmentDate" || name === "nextDate") {
        if (isValidAndNotEmptyString(value)) {
          return setValue(name, parse(value, "yyyy-MM-dd", new Date()));
        }
      }

      if (name === "nextTime") {
        const time = moment(value, "h:mm").format("LT");
        const d = moment(new Date(), "MM/DD/YYYY").format("L");
        const result = moment(d + " " + time, "MM/DD/YYYY h:mm a").format();

        return setValue(name, new Date(result));
      }

      if (name === "neighborhood" && neighborhoodList?.length) {
        return setValue(
          "neighborhood",
          neighborhoodList?.find(item => item.value === value) || { option: value, value }
        );
      }

      if (name === "neighborhoodName" && neighborhoodList?.length) {
        return setValue(
          "neighborhoodName",
          neighborhoodList?.find(item => item.value === value) || { option: value, value }
        );
      }

      if (name === "roomName" && roomsList?.length) {
        return setValue(
          "roomName",
          roomsList?.find(room => room.option === value)
        );
      }

      if (name === "title" && titleList?.length) {
        return setValue(
          name,
          titleList?.find(title => title === value)
        );
      }

      if (name === "city" && cityList?.length) {
        return setValue(
          name,
          cityList?.find(city => city === value)
        );
      }

      if (name === "windows" && !isNaN(parseInt(value))) {
        return setValue(name, parseInt(value));
      }

      setValue(name, value);
    });
  }
};
