import MenuIcon from "@mui/icons-material/Menu";
import { Box, CssBaseline, Divider, IconButton, List, ListItemButton, Toolbar, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";

import CustomizedBadge from "shared/components/CustomizedBadge/CustomizedBadge";
import { ROUTE_SETTINGS } from "shared/constants";

import DigitalSignSidebar from "../DigitalSignSidebar/DigitalSignSidebar";
import useNavigationDrawerLogic from "./hooks/useNavigationDrawerLogic";
import {
  AppBar,
  CompanyLogo,
  CustomizedDrawer,
  CustomizedListItem,
  CustomizedListItemText,
  DrawerHeader,
  Main,
  RightArrowIcon
} from "./style/navigationDrawerStyle";

const NavigationDrawer = ({ children, history }) => {
  const {
    open,
    onDrawerClick,
    role,
    companyLogo,
    navigationMap,
    onSignOut,
    digitalSignSidebar,
    currentPathname,
    onItemClick,
    showAdminSetting
  } = useNavigationDrawerLogic({ history });

  const renderListItem = (item, index = null) => {
    if (!item || item?.hide) return null;

    return (
      <CustomizedListItem
        key={index}
        selected={currentPathname.startsWith(item.route)}
        disablePadding
        onClick={() => onItemClick(item.route)}
        sx={{
          "&&.Mui-selected": {
            backgroundColor: "#000",
            "& > svg": {
              color: "#fff"
            }
          }
        }}
      >
        <ListItemButton>
          {item.badge ? (
            <CustomizedListItemText
              primary={
                <CustomizedBadge color="warning" positionRight="-7px">
                  {item.name}
                </CustomizedBadge>
              }
            />
          ) : (
            <CustomizedListItemText primary={item.name} />
          )}
        </ListItemButton>
        <RightArrowIcon />
      </CustomizedListItem>
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" onClick={onDrawerClick} edge="start" sx={{ mr: 2 }}>
            <MenuIcon sx={{ color: "#00203E" }} fontSize="large" />
          </IconButton>
          <Typography variant="h6" noWrap component="div" color="#00203E">
            Hybrid Management Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <CustomizedDrawer variant="persistent" anchor="left" open={open} role={role}>
        <DrawerHeader>
          <CompanyLogo src={companyLogo} alt="Company logo" />
        </DrawerHeader>
        <Divider />
        <List sx={{ marginTop: "40px" }}>{navigationMap.map((item, index) => renderListItem(item, index))}</List>

        <Box sx={{ marginTop: "auto" }}>
          <List>
            {renderListItem({ route: ROUTE_SETTINGS, name: "Admin Settings", hide: !showAdminSetting, badge: false })}

            <CustomizedListItem disablePadding>
              <ListItemButton onClick={onSignOut}>
                <CustomizedListItemText primary="Sign out" />
              </ListItemButton>
            </CustomizedListItem>
          </List>
        </Box>
        <Divider />
      </CustomizedDrawer>
      <Main open={open}>
        <DrawerHeader />
        {children}
      </Main>

      <DigitalSignSidebar history={history} isOpen={digitalSignSidebar.enable} setIsOpen={digitalSignSidebar.set} />
    </Box>
  );
};

NavigationDrawer.propTypes = {
  children: PropTypes.object.isRequired,
  history: PropTypes.object
};

export default withRouter(NavigationDrawer);
