import { addDays, format } from "date-fns";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { selectCToken } from "core/selectors";

import {
  useEmployeeAttendanceByDateQuery,
  useUpdatePastDailyAssignmentMutation
} from "features/attendance/attendanceApiSlice";
import {
  useGetEmployeeSettingsQuery,
  useSetOrUpdateEmployeeSettingMutation
} from "features/userManagement/userManagementApiSlice";

import { API_DATE_FORMAT, DAY_TYPES } from "shared/constants";
import { EEmployeeSettingName, ENonWorkingReason } from "shared/constants/settingConstants";

const defaultValues = {
  employee: { id: "", value: "" },
  date: addDays(new Date(), -1),
  dayType: ""
};

const mapStateToProps = (state: any) => [selectCToken(state)];

export default function useManualDayTypeSidebarLogic() {
  /* ------------------ HOOKs ------------------ */

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid },
    setValue,
    watch,
    setError
  } = useForm({
    mode: "onChange",
    defaultValues
  });

  /* ------------------ STATEs ------------------ */

  const [cToken] = useSelector(mapStateToProps);

  const [employee, date] = watch(["employee", "date"]);

  /* ------------------ APIs ------------------ */

  const { data: employeeAttendanceByDate, isLoading: isEmployeeAttendanceByDateLoading } =
    useEmployeeAttendanceByDateQuery(
      {
        employeeId: employee.id,
        fromDate: format(date, API_DATE_FORMAT),
        toDate: format(date, API_DATE_FORMAT)
      },
      { skip: !employee.id || !date, refetchOnMountOrArgChange: true }
    );

  const { data: employeeSettings, isLoading: isEmployeeSettingsLoading } = useGetEmployeeSettingsQuery(
    {
      cToken,
      employeeId: employee.id
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !employee.id
    }
  );
  const [
    updatePastDailyAssignment,
    {
      isLoading: isUpdatePastDailyAssignmentLoading,
      isSuccess: isUpdatePastDailyAssignmentSuccess,
      error: updatePastDailyAssignmentError
    }
  ] = useUpdatePastDailyAssignmentMutation();

  const [
    setOrUpdateEmployeeSetting,
    {
      isLoading: isSetOrUpdateEmployeeSettingLoading,
      isSuccess: isSetOrUpdateEmployeeSettingSuccess,
      error: setOrUpdateEmployeeSettingError
    }
  ] = useSetOrUpdateEmployeeSettingMutation();

  /* ------------------ HANDLERs ------------------ */

  const handleChangeDayType = ({
    dayType,
    employeeId,
    date
  }: {
    dayType: string;
    employeeId: string;
    date: string;
  }) => {
    const actualDayType = dayType === DAY_TYPES.armyService ? DAY_TYPES.nonWorking : dayType;

    Promise.allSettled([
      updatePastDailyAssignment({
        date,
        employeeId,
        dayType: actualDayType
      }),
      (async () => {
        if (actualDayType !== DAY_TYPES.nonWorking) return;

        return await setOrUpdateEmployeeSetting({
          cToken,
          employeeId,
          settingName: EEmployeeSettingName.nonWorkingReason,
          settingValue:
            dayType === DAY_TYPES.armyService ? ENonWorkingReason.armyService : ENonWorkingReason.maternityLeave
        });
      })()
    ])
      .then((res: any) => {
        const isSuccess = !res?.[0]?.error?.data;

        if (!isSuccess) {
          const errorMessage = res.error?.data?.details?.[0]?.errorMessage || "Failed to change day type";

          toast.error(errorMessage);
        } else {
          toast.success("Day type changed successfully");
        }
      })
      .catch(e => {
        toast.error("Failed to change day type");

        console.error(e);
      });
  };

  /* ------------------ EVENT EFFECTs ------------------ */

  const onSubmit = handleSubmit((data: { employee: { id: string; value: string }; date: Date; dayType: string }) => {
    if (!data.employee.id) {
      setError("employee", { message: "Employee is required" });
      return;
    }

    if (!data.dayType) {
      setError("dayType", { message: "Day type is required" });
      return;
    }

    handleChangeDayType({
      dayType: data.dayType,
      employeeId: data.employee.id,
      date: format(data.date, API_DATE_FORMAT)
    });
  });

  /* ------------------ EFFECTs ------------------ */

  useEffect(() => {
    if (!employeeAttendanceByDate?.length) return;

    const dayType = employeeAttendanceByDate[0].dayType;

    if (dayType === DAY_TYPES.nonWorking && employeeSettings.nonWorkingReason) {
      const type =
        employeeSettings.nonWorkingReason === ENonWorkingReason.armyService ? DAY_TYPES.armyService : dayType;

      setValue("dayType", type);
      return;
    }

    setValue("dayType", dayType);
  }, [employeeAttendanceByDate, setValue, employeeSettings]);

  return {
    control,
    errors,
    reset,
    onSubmit,
    isLoading: isEmployeeAttendanceByDateLoading || isEmployeeSettingsLoading,
    isSuccess: isUpdatePastDailyAssignmentSuccess || isSetOrUpdateEmployeeSettingSuccess,
    error: updatePastDailyAssignmentError || setOrUpdateEmployeeSettingError,
    isSubmitting: isUpdatePastDailyAssignmentLoading || isSetOrUpdateEmployeeSettingLoading,
    isValid
  };
}
