import format from "date-fns/format";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { selectAccessToken, selectBuildingFilter, selectSiteFilter } from "core/selectors";

import { useWorkstationsByBuildingsQuery } from "features/workstations/workstationApiSlice";

import { DAY_TYPES } from "shared/constants";
import { getWorkstationsOptions } from "shared/lib/getters";
import { capitalizeFirstLetter } from "shared/lib/stringsLib";
import { isEmptyObject } from "shared/lib/validation";

import { GREEN_COLOR } from "../style/weekPlanStyle";

const mapStateToProps = state => ({
  building: selectBuildingFilter(state),
  site: selectSiteFilter(state),
  token: selectAccessToken(state)
});

export default function useWeekPlanLogic({
  details,
  selectedIndex,
  setSelectedIndex,
  selectedRooms,
  dayTypes,
  handleChangeRoom,
  handleApplySelection
}) {
  /* ------------------ STATEs ------------------ */

  const [containerHeight, setContainerHeight] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const { building, site, token } = useSelector(mapStateToProps);

  /* ------------------ APIs ------------------ */

  const { data: workstationByBuildingsData, isLoading: isWorkstationByBuildingsLoading } =
    useWorkstationsByBuildingsQuery(
      { site, building, date: format(new Date(selectedDate), "yyyy-MM-dd") },
      { skip: !token || !site || !building || !selectedDate }
    );

  /* ------------------ MEMOs ------------------ */

  const workstationsByBuilding = useMemo(() => {
    if (
      isEmptyObject(workstationByBuildingsData) ||
      isWorkstationByBuildingsLoading ||
      !building ||
      !workstationByBuildingsData[building]?.length
    )
      return [];

    return getWorkstationsOptions(workstationByBuildingsData[building]);
  }, [workstationByBuildingsData, isWorkstationByBuildingsLoading, building]);

  const currentRoomData = useMemo(
    () => selectedRooms?.find(room => room?.date === details.date),
    [selectedRooms, details]
  );

  const currentDayData = useMemo(() => dayTypes?.find(day => day?.date === details.date), [dayTypes, details]);

  const isApplyDisabled = useMemo(
    () =>
      currentDayData?.type?.toLowerCase() === details.dayType?.toLowerCase() &&
      currentDayData?.type?.toLowerCase() === "office" &&
      !currentRoomData?.item,
    [currentDayData, details, currentRoomData]
  );

  /* ------------------ HANDLERs ------------------ */

  const handleExpandClick = async index => {
    if (selectedIndex === index) {
      setSelectedIndex("");
      setSelectedDate(null);
    } else {
      setSelectedIndex(index.toString());
      setSelectedDate(details.date);
    }
  };

  const getRoomColor = room => {
    let color = GREEN_COLOR;

    if (room) {
      color = "#00B4F0";
    }

    if (!room && details?.dayType?.toLowerCase() === DAY_TYPES.office) {
      color = "#d52b1e";
      if (currentRoomData?.item) {
        color = GREEN_COLOR;
      }
    }

    return color;
  };

  const getMonthAndDate = dateStr => {
    if (!dateStr) return "";

    return format(new Date(dateStr), "MMM, do");
  };

  const getDayName = dateStr => {
    if (dateStr) {
      const date = new Date(dateStr);
      const name = date.toLocaleDateString("en-US", { weekday: "long" });
      const dayOfMonth = format(new Date(dateStr), "do");
      return `${name}, ${dayOfMonth}`;
    } else {
      return "";
    }
  };

  const getDayTypeText = type => {
    let text = type;
    if (type === DAY_TYPES.office && !currentRoomData?.item) {
      text = "No room assigned";
    }

    return capitalizeFirstLetter(text);
  };

  /* ------------------ EVENT HANDLERs ------------------ */

  const onSelectWorkstation = (e, item, reason) => {
    handleChangeRoom(item, details.date, reason, currentDayData?.type?.toLowerCase() || details?.dayType);
  };

  const onApplyDailyAssignment = () => {
    handleApplySelection(currentRoomData?.item, details.date, currentDayData?.type?.toLowerCase());
  };

  return {
    setContainerHeight,
    getMonthAndDate,
    getRoomColor,
    getDayTypeText,
    handleExpandClick,
    containerHeight,
    getDayName,
    workstationsByBuilding,
    isWorkstationByBuildingsLoading,
    currentDayData,
    currentRoomData,
    onSelectWorkstation,
    onApplyDailyAssignment,
    isApplyDisabled
  };
}
