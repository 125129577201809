import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import { selectCurrentSiteAndBuildingFiltersText } from "core/selectors";

import FilterButton from "../FilterButton/FilterButton";

const SiteAndBuilding = ({ onClick, title = "Site and Building" }) => {
  const currentFilter = useSelector(selectCurrentSiteAndBuildingFiltersText);

  return (
    <FilterButton onClick={onClick}>
      <Typography variant="body1">{currentFilter || title}</Typography>
      <StyledArrow />
    </FilterButton>
  );
};

const StyledArrow = styled(ArrowDropDownIcon)({
  marginLeft: "8px",
  color: "#000",
  marginTop: "-4px",
  marginBottom: "-4px"
});

SiteAndBuilding.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string
};

export default SiteAndBuilding;
