import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

import { getFullName } from "shared/lib/getters";

import NotInBWith from "../../NotInBWith";
import AttendanceDays from "../components/AttendanceDays";
import AttendanceDaysTitle from "../components/AttendanceDaysTitle";
import { Row } from "../style/currentWeekTabStyle";

interface Params {
  row: {
    firstName: string;
    lastName: string;
    status: string;
    employeeId: string;
  };
  value?: any[]; // Adjust this type according to the actual structure of params.value
}

const currentWeekTableColumns = (
  firstTableItem: any,
  onChangeDayType: (
    data: { dayType: string; employeeId: string; date: string },
    cb: (isSuccess: boolean) => void
  ) => void
) => [
  {
    field: "name",
    headerName: "Name",
    width: 140,
    valueGetter: (params: Params) => getFullName(params.row.firstName, params.row.lastName),
    renderCell: (params: Params) => (
      <Row>
        <Typography>{getFullName(params.row.firstName, params.row.lastName)}</Typography>

        {params.row.status !== "ACTIVE" ? <NotInBWith /> : null}
      </Row>
    )
  },
  {
    field: "department",
    headerName: "Department",
    width: 140
  },
  {
    field: "title",
    headerName: "Title",
    width: 140
  },
  {
    field: "officeDays",
    width: 90,
    renderHeader: () => {
      return (
        <Box>
          <Typography>Days</Typography>
          <Typography>/week</Typography>
        </Box>
      );
    }
  },
  {
    field: "schedule",
    sortable: false,
    flex: 1,
    headerClassName: "work-days-table-header",
    minWidth: 480,
    renderHeader: (param: any) => {
      if (!firstTableItem) return null;

      return (
        <>
          {firstTableItem[param.field].map((item: any, index: number) => (
            <AttendanceDaysTitle item={item} key={index} />
          ))}
        </>
      );
    },
    renderCell: (params: Params) => {
      if (!params?.value?.length) return null;

      return (
        <>
          {params.value.map((item: any, index: number) => {
            return (
              <AttendanceDays
                item={item}
                employeeId={params.row.employeeId}
                key={index}
                onChangeDayType={onChangeDayType}
              />
            );
          })}
        </>
      );
    }
  }
];

export default currentWeekTableColumns;
