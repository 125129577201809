export function isCustomWeekend(date: Date, weekStartsOn: number): boolean {
  const day = date.getDay();
  if (weekStartsOn === 0) {
    // If the week starts on Sunday, consider Friday and Saturday as weekends
    return day === 5 || day === 6;
  } else {
    // Otherwise, consider Saturday and Sunday as weekends
    return day === 6 || day === 0;
  }
}
