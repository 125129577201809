import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { selectCompanyAndSiteSettings, selectUserEmail } from "core/selectors";

import { useCompanyStylesQuery, useRoleByNameMutation } from "features/adminSettings/adminSettingsApiSlice";
import { useConfirmMutation, useLoginMutation } from "features/auth/authApiSlice";
import { setNavigationResources, setRole, setUserInfo } from "features/auth/authSlice";
import { setCompanySite } from "features/site/siteSlice";

import {
  LOGIN_LOGO_SIZE,
  RESEND_CODE_TYPE,
  ROLE_PREFIX,
  ROLE_USER,
  STORAGE_NAVIGATION_RESOURCES,
  STORAGE_ROLE,
  STORAGE_USER_INFO
} from "shared/constants";
import { useCountdown } from "shared/hooks/useCountdown";
import { getBrowserName } from "shared/lib/getters";
import { isValidNumber } from "shared/lib/validation";

const COUNTDOWN_DURATION = 30 * 1000; // ms

const DEFAULT_LOGO_SIZE = { width: LOGIN_LOGO_SIZE, height: 108 };

export default function useConfirmationCodePageLogic({ history }) {
  const smsCountdown = useCountdown(COUNTDOWN_DURATION);
  const emailCountdown = useCountdown(COUNTDOWN_DURATION);

  const [code, setCode] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [logoSize, setLogoSize] = useState(DEFAULT_LOGO_SIZE);

  const [email, companyAndSiteSettings] = useSelector(state => [
    selectUserEmail(state),
    selectCompanyAndSiteSettings(state)
  ]);
  const dispatch = useDispatch();

  const { data: companyStylesData, isLoading: isLoadingCompanyStyle } = useCompanyStylesQuery(
    { email },
    { skip: !email }
  );

  const [login] = useLoginMutation();
  const [confirm, { isLoading }] = useConfirmMutation();
  const [getRoleByName] = useRoleByNameMutation();

  const handleLogoSize = ($x, $y) => {
    const x = Number($x);
    const y = Number($y);

    if (!(x && y && !isNaN(x) && !isNaN(y))) return DEFAULT_LOGO_SIZE;
    const xToY = x / y;

    const width = xToY > 1 ? LOGIN_LOGO_SIZE : LOGIN_LOGO_SIZE * xToY;
    const height = xToY < 1 ? LOGIN_LOGO_SIZE : LOGIN_LOGO_SIZE / xToY;

    return { width, height };
  };

  const roleHandler = async roles => {
    const role = roles?.length ? roles[0].replace(ROLE_PREFIX, "") : ROLE_USER;

    dispatch(setRole(role));
    localStorage.setItem(STORAGE_ROLE, role);

    try {
      const response = await getRoleByName({ role }).unwrap();

      localStorage.setItem(STORAGE_NAVIGATION_RESOURCES, JSON.stringify(response?.resources));
      dispatch(setNavigationResources(response?.resources));
    } catch (error) {
      console.error("ERROR :: gerRoleInfoHandler ::", { error, role });
    }
  };

  const confirmSuccessHandler = async data => {
    if (!data) return;

    const parsed = jwtDecode(data.accessToken);
    const userInfo = {
      email,
      accessToken: data.accessToken,
      refreshToken: data.refreshToken,
      cToken: parsed.cToken,
      site: parsed.site
    };

    localStorage.setItem(STORAGE_USER_INFO, JSON.stringify(userInfo));
    dispatch(setCompanySite(parsed.site));
    dispatch(setUserInfo(userInfo));

    await roleHandler(parsed?.roles);
  };

  const handleChange = e => {
    const value = e.target.value;
    setCode(value);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!isValidNumber(parseInt(code))) {
      setErrorMsg("Incorrect code address");
      return;
    }
    setErrorMsg("");

    try {
      const deviceName = `Desktop ${getBrowserName()}`;
      const data = await confirm({ email, code, deviceName }).unwrap();

      confirmSuccessHandler(data);
    } catch (error) {
      if (error?.data) {
        // TODO: use actual ERROR code. Need changes on BE
        if (error?.data?.message === "User with provided email is not allowed to login!") {
          toast.error("Your user does not have dashboard permissions.", {
            autoClose: 10000
          });
        }

        setErrorMsg(error.data?.message);
      } else {
        setErrorMsg("Something went wrong. Please try again.");
      }
    }
  };

  useEffect(() => {
    if (companyStylesData?.logoUrl) {
      setLogoUrl(companyStylesData.logoUrl);
    }

    if (companyStylesData?.logoHeightPx && companyStylesData?.logoWidthPx) {
      setLogoSize(handleLogoSize(companyStylesData?.logoWidthPx, companyStylesData?.logoHeightPx));
    }
  }, [companyStylesData, isLoadingCompanyStyle]);

  const onSendSms = () => {
    login({ email, notificationType: RESEND_CODE_TYPE.SMS });

    smsCountdown.on();
  };

  const onSendEmail = () => {
    login({ email, notificationType: RESEND_CODE_TYPE.EMAIL });

    emailCountdown.on();
  };

  return {
    handleSubmit,
    errorMsg,
    handleChange,
    code,
    isLoading,
    onSendSms,
    onSendEmail,
    smsCountdown,
    emailCountdown,
    logoUrl,
    logoSize,
    isLoadingCompanyStyle
  };
}
