import React from "react";

import { getRoleName } from "shared/lib";

export const columnsUsers = [
  {
    field: "name",
    headerName: "Name",
    editable: false,
    flex: 1,
    minWidth: 220,
    valueGetter: params => {
      return `${params.row?.firstName} ${params.row?.lastName}`;
    }
  },
  {
    field: "role",
    headerName: "Role",
    editable: false,
    flex: 2,
    valueGetter: params => {
      return getRoleName(params.row?.role);
    }
  }
];

export const deleteUserModalContent = {
  title: "Reset user permissions",
  body: (
    <div>
      <span>If you reset user permissions to default:</span>
      <ul>
        <li>The user will no longer be able to access the BWith dashboard</li>
      </ul>
    </div>
  ),
  warningText: ""
};

export const deleteNeighborhoodTitleText = "Delete Neighborhood";

export const deleteNeighborhoodContentText = (
  <div>
    <span>If you choose to delete this neighborhood:</span>
    <ul>
      <li>All workstations in this neighborhood will change to a general neighborhood</li>
      <li>Any user will be able to sit in these workstations</li>
    </ul>
  </div>
);

export const neighborhoodColumns = [
  {
    field: "name",
    headerName: "Name",
    editable: false,
    flex: 1
  },
  {
    field: "site",
    headerName: "Site",
    editable: false,
    flex: 1
  },
  {
    field: "building",
    headerName: "Building",
    editable: false,
    flex: 1
  }
];
