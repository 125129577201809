import { Button as MuiButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Subtitle = styled(Typography)`
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #6b6c72;
  margin-bottom: 1rem;
`;

export const Button = styled(MuiButton)(({ theme }) => ({
  width: "100%",
  fontSize: "1rem",
  lineHeight: "1.25rem",
  textAign: "center",
  padding: theme.spacing(1, 2),
  color: "#000",
  border: "1px solid #000",
  borderRadius: "25px",

  "& span": {
    width: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    textWrap: "nowrap"
  },

  "&:disabled": {
    borderColor: "#6b6c72"
  }
}));

export const styles = {
  selectStyles: {
    minWidth: "84px",
    backgroundColor: "transparent",
    border: "none"
  },
  selectContainerStyles: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    border: "1px solid #000",
    borderRadius: "25px",
    overflow: "hidden",

    "&.select-disabled": {
      borderColor: "#6b6c72"
    }
  }
};
