import { useMemo, useRef, useState } from "react";

const DEFAULT_COUNTDOWN_STEP = 1000; // ms

/**
 *
 * @param duration ms
 * @param step ms
 */
export function useCountdown(duration: number, step: number = DEFAULT_COUNTDOWN_STEP) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const timeRef = useRef<number>(duration);
  const enableRef = useRef<boolean>(false);

  const [display, setDisplay] = useState<string>("");
  const [enable, setEnable] = useState<boolean>(false);

  const timerHandler = (time: number): number => {
    if (!time) return 0;

    const timeInSeconds = time / 1000;
    const minutes = (timeInSeconds / 60) | 0;
    const seconds = timeInSeconds % 60 | 0;

    const displayMinutes = minutes < 10 ? "0" + minutes : minutes;
    const displaySeconds = seconds < 10 ? "0" + seconds : seconds;

    setDisplay(`${displayMinutes}:${displaySeconds}`);

    return time - step;
  };

  const setupHandler = () => {
    timeoutRef?.current && clearTimeout(timeoutRef.current);
    timeRef.current = timerHandler(timeRef?.current);

    if (timeRef?.current > 0 && enableRef?.current) {
      timeoutRef.current = setTimeout(setupHandler, step);
    } else {
      offHandler();
    }
  };

  const onHandler = () => {
    setEnable(true);
    enableRef.current = true;

    setupHandler();
  };

  const offHandler = () => {
    setEnable(false);
    enableRef.current = false;
    timeRef.current = duration;
    timeoutRef?.current && clearTimeout(timeoutRef.current);
  };

  const methods = useMemo(() => ({ on: onHandler, off: offHandler }), [onHandler, offHandler]);

  return {
    ...methods,
    display,
    enable
  };
}
