import { Box, Grid, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import React from "react";

import Sidebar from "shared/components/Sidebar/Sidebar";
import { EXPORT_ATTENDANCE_TYPE } from "shared/constants";

import FormInputDate from "../FormInputs/Date/FormInputDate";
import { Button, Subtitle } from "./ExportFileSidebarStyle";
import useExportFileSidebarLogic, { ExportFileSidebarLogicProps } from "./useExportFileSidebarLogic";

type ExportFileSidebarProps = {
  isOpen: boolean;
  loading: boolean;
  success: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose: () => void;
  error: any;
} & ExportFileSidebarLogicProps;

const ExportFileSidebar = ({
  isOpen,
  setIsOpen,
  onClose,
  loading,
  success,
  error,
  ...props
}: ExportFileSidebarProps) => {
  const { onExportHistory, control } = useExportFileSidebarLogic({
    ...props
  });

  return (
    // @ts-ignore
    <Sidebar
      history={history}
      isOpen={isOpen}
      isDrawer={true}
      setIsOpen={setIsOpen}
      onResolve={onClose}
      resolveLabel={"Close"}
      isLoading={loading}
      isSuccess={success}
      error={error}
      showCancelButton={false}
    >
      <Box>
        <Typography variant="h5" fontWeight="700">
          {"Export"}
        </Typography>
      </Box>

      <Grid container rowSpacing={1} spacing={1} mb={3}>
        <Grid item width="100%" mt={2}>
          <Divider color="#8D9096" />
        </Grid>

        <Grid item width="100%" mt={1} mb={-1}>
          <Subtitle variant="h6" fontWeight="normal">
            Select report date range:
          </Subtitle>
        </Grid>

        <Grid item xs={6}>
          {/* @ts-ignore */}
          <FormInputDate control={control} label="Start date" name="startDate" fullWidth />
        </Grid>

        <Grid item xs={6}>
          {/* @ts-ignore */}
          <FormInputDate control={control} label="End date" name="endDate" fullWidth />
        </Grid>

        <Grid item width="100%" mt={2}>
          <Divider color="#8D9096" />
        </Grid>

        <Grid item width="100%" mt={1}>
          <Subtitle variant="h6" fontWeight="normal">
            Attendance export
          </Subtitle>
        </Grid>

        <Grid item xs={6} display={"flex"} alignItems={"flex-end"}>
          <Box width={"100%"}>
            <Button onClick={onExportHistory(EXPORT_ATTENDANCE_TYPE.singleDay)}>{"Single day detailed export"}</Button>
          </Box>
        </Grid>

        <Grid item xs={6} display={"flex"} alignItems={"center"}>
          <Typography variant="body2" fontWeight="normal">
            Select the date using ‘Start date’
          </Typography>
        </Grid>

        <Grid item xs={12} mt={2}>
          <Box width={"50%"} pr={2}>
            <Button onClick={onExportHistory()} disabled={loading} title="Historic export">
              {"Historic export"}
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} mt={2}>
          <Box width={"50%"} pr={2}>
            <Button
              onClick={onExportHistory(EXPORT_ATTENDANCE_TYPE.daysPerWeek)}
              disabled={loading}
              title="Department - avg. days per week"
            >
              {"Department - avg. days per week"}
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} mt={2}>
          <Box width={"50%"} pr={2}>
            <Button
              onClick={onExportHistory(EXPORT_ATTENDANCE_TYPE.employeesPerDay)}
              disabled={loading}
              title="Department - avg. empl. per day"
            >
              {/* @ts-ignore */}
              <Typography variant="span">{"Department - avg. empl. per day"}</Typography>
            </Button>
          </Box>
        </Grid>

        <Grid item width="100%" mt={2}>
          <Divider color="#8D9096" />
        </Grid>

        <Grid item width="100%" mt={1}>
          <Subtitle variant="h6" fontWeight="normal">
            {"Utilization export"}
          </Subtitle>
        </Grid>

        <Grid item xs={12} mt={1}>
          <Box width={"50%"} pr={2}>
            <Button
              onClick={onExportHistory(EXPORT_ATTENDANCE_TYPE.workstationsPlan)}
              disabled={loading}
              title="Room utilization & no-show"
            >
              {"Room utilization & no-show"}
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} mt={2}>
          <Box width={"50%"} pr={2}>
            <Button
              onClick={onExportHistory(EXPORT_ATTENDANCE_TYPE.employeesPlan)}
              disabled={loading}
              title="Employee room usage"
            >
              {"Employee room usage"}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Sidebar>
  );
};

export default ExportFileSidebar;
