import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { selectAccessToken, selectCompanySites } from "core/selectors";

import {
  useDeleteNationalHolidaysMutation,
  useNationalHolidaysQuery
} from "features/adminSettings/adminSettingsApiSlice";

import { useToggle } from "shared/hooks/useToggle";

export default function useAdminSettingsHolidays() {
  const holidaySidebar = useToggle();
  const deleteHolidayDialog = useToggle();

  const token = useSelector(selectAccessToken);
  const companySites = useSelector(selectCompanySites);

  const [holidayId, setHolidayId] = useState<string | undefined>(undefined);

  const { data: holidaysData, isLoading: isHolidaysLoading } = useNationalHolidaysQuery(null, { skip: !token });
  const [deleteNationalHolidays, { isLoading: isLoadingDeleteHoliday }] = useDeleteNationalHolidaysMutation();

  const listHolidays = useMemo(
    () =>
      holidaysData?.length
        ? holidaysData.map(({ siteIds, ...item }: any) => {
            const sites = siteIds?.map((id: string) => {
              const site = companySites?.find((item: any) => item.id === id);

              return site?.name;
            });

            return {
              ...item,
              sites
            };
          })
        : [],
    [holidaysData, companySites]
  );

  const onInfoHoliday = (id: string) => {
    setHolidayId(id);
    holidaySidebar.on();
  };

  const onDeleteHoliday = (id: string) => {
    setHolidayId(undefined);
    deleteNationalHolidays({ id });
  };

  const onSidebarDeleteHoliday = () => {
    holidaySidebar.off();
    deleteHolidayDialog.on();
  };

  const onAddHoliday = () => {
    setHolidayId(undefined);
    holidaySidebar.on();
  };

  return {
    listHolidays: listHolidays || [],
    onInfoHoliday,
    onDeleteHoliday,
    onAddHoliday,
    isHolidaysLoading: isLoadingDeleteHoliday || isHolidaysLoading,
    holidaySidebar,
    holidayId,
    deleteHolidayDialog,
    onSidebarDeleteHoliday
  };
}
