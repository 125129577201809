import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import { withRouter } from "react-router-dom";

import PageTitle from "shared/components/PageTitle";

import CurrentWeekTab from "./components/CurrentWeekTab/CurrentWeekTab";
import InsightsTab from "./components/InsightsTab";
import PreviousWeeksTab from "./components/PreviousWeeksTab/PreviousWeeksTab";
import useAttendanceLogic from "./hooks/useAttendanceLogic";
import { Container, Content, Summary, SummaryText, styles } from "./style/attendanceStyle";

const TABS_LIST = ["This week", "Prev. weeks", "Insights"];

const Attendance = () => {
  const { tab, onChangeTab, summary, setCurrentWeekSummary, setPreviousWeeksSummary } = useAttendanceLogic();

  return (
    <Container>
      <PageTitle title="Attendance" />

      <Box sx={styles.tabsContainerStyle}>
        <Tabs value={tab} onChange={onChangeTab} textColor="secondary" indicatorColor="secondary" sx={styles.tabsStyle}>
          {TABS_LIST.map((tab, index) => (
            <Tab key={index} value={index} label={tab} sx={styles.tabItemStyle} />
          ))}

          {tab !== 2 ? (
            <Summary>
              <SummaryText>{`Showing ${summary.users} users, ${summary.days} working days`}</SummaryText>
            </Summary>
          ) : null}
        </Tabs>
      </Box>

      <Content>
        {tab === 0 ? <CurrentWeekTab setSummary={setCurrentWeekSummary} /> : null}

        {tab === 1 ? <PreviousWeeksTab setSummary={setPreviousWeeksSummary} /> : null}

        {tab === 2 ? <InsightsTab /> : null}
      </Content>
    </Container>
  );
};

export default withRouter(Attendance);
