import { Box, Button, Container, Icon, TextField } from "@mui/material";
import styled from "@mui/material/styles/styled";

import BackgroundImage from "shared/assets/images/login-bright-background.png";

export const CustomizedDiv = styled("div")`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
`;

export const CustomizedContainer = styled(Container)`
  min-width: 750px;
  min-height: 600px;
  border-radius: 13px;
  background-image: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: none;
  padding-top: 180px;
  position: relative;
`;

export const CustomizedIcon = styled(Icon)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: theme.spacing(0.5, 4),
  borderRadius: 8,
  boxShadow: "0 5px 20px 3px #a9a9a9",
  position: "absolute",
  left: "50%",
  transform: "translate(-50%, -50%)"
}));

export const LogoImg = styled("img")`
  width: 100%;
  height: 100%;
  display: "block";
`;

export const CustomizedTextField = styled(TextField)({
  color: "rgba(21, 20, 57, 0.4)",
  background: "#FFFFFF",
  borderRadius: "100px",
  minWidth: "300px",
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      border: "none"
    },
    "& fieldset": {
      border: "none"
    }
  }
});

export const CustomizedSubmitButton = styled(Button)({
  background: "#000000",
  color: "#ffffff",
  borderRadius: "100px",
  fontSize: "1rem",
  "&.Mui-disabled": {
    background: "#cccccc"
  }
});

export const CustomizedErrorText = styled(Box)`
  position: absolute;
  width: 100%;
  top: -20px;
  z-index: 10;
  text-align: center;
`;

export const CustomizedButton = styled(Button)`
  color: #6b6c72;
`;
