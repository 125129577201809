import { Box, styled } from "@mui/material";

export const ContainerBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100%"
}));

export const BodyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1
}));

// export const
