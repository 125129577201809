import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { selectAccessToken } from "core/selectors";

import { useRolesQuery, useUpdateAllowedDepartmentsMutation } from "features/adminSettings/adminSettingsApiSlice";
import { useUpdateUserMutation, useUsersQuery } from "features/userManagement/userManagementApiSlice";

import { useToggle } from "shared/hooks/useToggle";
import { getAdminUsers } from "shared/lib";

export default function useAdminSettingsUsers() {
  const userSidebar = useToggle();

  const token = useSelector(selectAccessToken);

  const [listAdmins, setListAdmins] = useState<any[]>([]);
  const [userId, setUserId] = useState<string | undefined>(undefined);

  const { data: userData, isLoading: isUserLoading } = useUsersQuery<any>(undefined, { skip: !token });
  const { data: rolesData, isLoading: isRolesLoading } = useRolesQuery(undefined, { skip: !token });
  const [updateUser] = useUpdateUserMutation();
  const [updateAllowedDepartments] = useUpdateAllowedDepartmentsMutation();

  useEffect(() => {
    if (!userData?.employees?.length || isUserLoading || isRolesLoading || !rolesData?.length) return;

    const list = getAdminUsers(userData.employees, rolesData);
    setListAdmins(list);
  }, [userData, isUserLoading, rolesData, isRolesLoading]);

  const onEditUser = (id: string) => {
    userSidebar.on();

    setUserId(id);
  };

  const onDeleteUser = (id: string) => {
    //@ts-ignore
    updateUser({ id, params: { role: "USER" } });
    updateAllowedDepartments({ id, departmentsIds: [] });

    setUserId(undefined);
  };

  const onAddUser = () => {
    setUserId(undefined);
    userSidebar.on();
  };

  const onCloseUserSidebar = (value: boolean) => {
    setUserId(undefined);
    userSidebar.set(value);
  };

  return {
    listAdmins,
    onEditUser,
    onDeleteUser,
    onAddUser,
    isUserLoading,
    userSidebar,
    userId,
    onCloseUserSidebar
  };
}
