import React, { useMemo } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import ConfirmPlan from "pages/ConfirmPlan";
import ConfirmationCodePage from "pages/ConfirmationCodePage/ConfirmationCodePage";
import SignInPage from "pages/SignInPage/SignInPage";

import FiltersDialog from "shared/components/Dialogs/FiltersDialog/FiltersDialog";
import PageContainer from "shared/components/PageContainer/PageContainer";
import UserWithoutWorkstationContainer from "shared/components/UserWithoutWorkstationContainer/UserWithoutWorkstationContainer";
import { ROUTE_CONFIRMATION_CODE, ROUTE_CONFIRM_PLAN_REDIRECT, ROUTE_LOGIN } from "shared/constants";

import useAppRouterLogic from "./hooks/useAppRouterLogic";
import { APP_ROUTES } from "./routes";

type AppRouterProps = {
  location: any;
  history: any;
};

const AppRouter = (props: AppRouterProps) => {
  const { isOpenDialog, setIsOpenDialog, role, authenticated, redirectToRoute, isWorkstationAccess } =
    useAppRouterLogic(props);

  const renderToast = useMemo(
    () => <ToastContainer position="top-right" autoClose={5000} hideProgressBar newestOnTop={false} rtl={false} />,
    []
  );

  const renderFiltersDialog = useMemo(() => {
    if (!isOpenDialog) return null;

    return <FiltersDialog isOpen={isOpenDialog} setIsOpen={setIsOpenDialog} />;
  }, [role, isOpenDialog, setIsOpenDialog]);

  const renderUserWithoutWorkstations = useMemo(() => {
    if (!isWorkstationAccess || !authenticated) return null;

    return <UserWithoutWorkstationContainer />;
  }, [isWorkstationAccess, authenticated]);

  return (
    <>
      <Switch>
        <Route path={`/${ROUTE_CONFIRM_PLAN_REDIRECT}`}>
          <ConfirmPlan />
        </Route>

        {authenticated ? (
          <>
            {APP_ROUTES.filter(route => route.enabled).map(routeProps => (
              <Route key={routeProps.path} path={routeProps.path} exact={routeProps.exact}>
                {/* @ts-ignore */}
                <PageContainer {...routeProps} />
              </Route>
            ))}
            <Redirect to={redirectToRoute} />
          </>
        ) : (
          <>
            <Route exact path={`/${ROUTE_LOGIN}`}>
              <SignInPage />
            </Route>
            <Route exact path={`/${ROUTE_LOGIN}/${ROUTE_CONFIRMATION_CODE}`}>
              <ConfirmationCodePage />
            </Route>
          </>
        )}
      </Switch>

      {renderUserWithoutWorkstations}
      {renderFiltersDialog}
      {renderToast}
    </>
  );
};

export default withRouter(AppRouter);
