import React, { useCallback } from "react";

import DataTable from "shared/components/DataTable/DataTable";
import ManualDayTypeSidebar from "shared/components/ManualDayTypeSidebar/ManualDayTypeSidebar";

import { calculateTotalArrivals } from "../../helpers";
import TotalArrivalsFooter from "../TotalArrivalsFooter";
import useCurrentWeekTabLogic from "./hooks/useCurrentWeekTabLogic";
import { Container, styles } from "./style/currentWeekTabStyle";

type CurrentWeekTabProps = {
  setSummary: (summary: { users: number; days: number }) => void;
};

const CurrentWeekTab = ({ setSummary }: CurrentWeekTabProps) => {
  const { rows, columns, isLoading, formattedStartDate, formattedEndDate, manualDayTypeSidebar } =
    useCurrentWeekTabLogic({
      setSummary
    });

  const renderFooterComponent = useCallback((): React.ReactNode => {
    // (_page: any, _rowsPerPage: any): React.ReactNode => {
    // const paginatedRows = dataByPagination(getState(), page, rowsPerPage); // TODO: Use if need pagination

    const arrivalsData = calculateTotalArrivals(rows, {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      sundayFirst: true,
      addWeekends: false,
      monthNumber: undefined
    });

    // @ts-ignore
    return <TotalArrivalsFooter title="Total actual arrivals" titleWidth={"510px"} arrivalsData={arrivalsData} />;
  }, [rows, formattedStartDate, formattedEndDate]);

  return (
    <Container>
      {/* @ts-ignore */}
      <DataTable
        rows={rows}
        rowIdFieldName={"employeeId"}
        columns={columns}
        showDeleteColumn={false}
        showEditColumn={false}
        showInfoColumn={false}
        showSiteAndBuilding
        showFloorSelector
        showDepartmentSelector
        showSearch
        autoHeight={false}
        isLoading={isLoading}
        sx={styles.tableStyle}
        renderFooterComponent={renderFooterComponent}
        onEditDate={manualDayTypeSidebar.on}
        perPage={25}
        // onTableStateChange={onTableStateChange} // TODO: Use if need pagination
      />

      <ManualDayTypeSidebar
        isOpen={manualDayTypeSidebar.enable}
        onClose={manualDayTypeSidebar.off}
        setIsOpen={manualDayTypeSidebar.set}
      />
    </Container>
  );
};

export default CurrentWeekTab;
