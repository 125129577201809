export const ROUTE_LOGIN = "login";

export const ROUTE_CONFIRMATION_CODE = "confirmation";

export const ROUTE_LIVE_VIEW = "live-view";

export const ROUTE_USER_MANAGEMENT = "user-management";

export const ROUTE_WORKSTATIONS = "workstation";

export const ROUTE_SETTINGS = "settings";

export const ROUTE_TEAM = "team";

export const ROUTE_INDIVIDUAL = "individual";

export const ROUTE_ACTIVITIES = "activities";

export const ROUTE_DIGITAL_SIGN = "digital-sign";

export const ROUTE_ATTENDANCE = "attendance";

export const ROUTE_CONFIRM_PLAN_REDIRECT = "confirm-plan";

export const DEFAULT_RESOURCES_NAMES = {
  liveView: "live view",
  workstations: "workstations",
  users: "users",
  neighborhoods: "neighborhoods"
};

export const RESOURCES_NAMES = {
  ...DEFAULT_RESOURCES_NAMES,
  attendance: "attendance",
  activities: "activities",
  individual: "individual",
  team: "team",
  adminSettings: "adminSettings"
};
