import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  selectAccessToken,
  selectBuildingFilter,
  selectCToken,
  selectCompanyAndSiteSettings,
  selectDepartmentsFilter,
  selectFloorsFilter,
  selectSiteFilter
} from "core/selectors";
import { selectCompanyId, selectSiteName } from "core/selectors/profileSelectors";

import { useAttendanceQuery, useUpdateDailyAssignmentMutation } from "features/attendance/attendanceApiSlice";
import {
  useEmployeesSettingsByNameQuery,
  useSetOrUpdateEmployeeSettingMutation
} from "features/userManagement/userManagementApiSlice";

import { fillEmployeesSchedules } from "pages/Attendance/helpers";

import { API_DATE_FORMAT, DAY_TYPES, WORK_DAYS_COUNT } from "shared/constants";
import { EEmployeeSettingName, ENonWorkingReason } from "shared/constants/settingConstants";
import { useToggle } from "shared/hooks/useToggle";
import useWorkWeekDates from "shared/hooks/useWorkWeekDates";

import currentWeekTableColumns from "../utils/currentWeekTableColumns";

const mapStateToProps = (state: any) => [
  selectAccessToken(state),
  selectCToken(state),
  selectCompanyAndSiteSettings(state),
  selectBuildingFilter(state),
  selectFloorsFilter(state),
  selectDepartmentsFilter(state),
  selectSiteName(state),
  selectSiteFilter(state),
  selectCompanyId(state)
];

export default function useCurrentWeekTabLogic({
  setSummary
}: {
  setSummary: (summary: { users: number; days: number }) => void;
}) {
  /* ------------------ HOOKs ------------------ */
  const manualDayTypeSidebar = useToggle();

  const { startWeek, endWeek } = useWorkWeekDates();

  const formattedStartDate = format(startWeek, API_DATE_FORMAT);
  const formattedEndDate = format(endWeek, API_DATE_FORMAT);

  /* ------------------ STATEs ------------------ */

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [token, cToken, companyAndSiteSettings, building, floors, departments, siteName, site, companyId] =
    useSelector(mapStateToProps);

  /* ------------------ APIs ------------------ */

  const { data: attendanceData, isLoading } = useAttendanceQuery(
    {
      fromDate: formattedStartDate,
      toDate: formattedEndDate,
      building,
      departments,
      floors
    },
    { skip: !token }
  );

  const { data: employeeSettings, isLoading: isEmployeeSettingsLoading } = useEmployeesSettingsByNameQuery(
    {
      settingName: EEmployeeSettingName.nonWorkingReason,
      site
    },
    { skip: !site }
  );

  const [updateDailyAssignment] = useUpdateDailyAssignmentMutation();

  const [setOrUpdateEmployeeSetting] = useSetOrUpdateEmployeeSettingMutation();

  /* ------------------ HANDLERs ------------------ */

  const handleChangeDayType = (
    { dayType, employeeId, date }: { dayType: string; employeeId: string; date: string },
    cb?: any
  ) => {
    const actualDayType = dayType === DAY_TYPES.armyService ? DAY_TYPES.nonWorking : dayType;

    Promise.allSettled([
      updateDailyAssignment({
        cToken,
        date,
        employeeId,
        dayType: actualDayType,
        companyId,
        site: siteName,
        seatId: null
      }),
      (async () => {
        if (actualDayType !== DAY_TYPES.nonWorking) return;

        return await setOrUpdateEmployeeSetting({
          cToken,
          employeeId,
          settingName: EEmployeeSettingName.nonWorkingReason,
          settingValue:
            dayType === DAY_TYPES.armyService ? ENonWorkingReason.armyService : ENonWorkingReason.maternityLeave
        });
      })()
    ])
      .then((res: any) => {
        const isSuccess = !res?.[0]?.error?.data;

        if (!isSuccess) {
          const errorMessage = res.error?.data?.details?.[0]?.errorMessage || "Failed to change day type";

          toast.error(errorMessage);
        } else {
          toast.success("Day type changed successfully");
        }

        cb?.(isSuccess);
      })
      .catch(e => {
        cb?.(false);

        toast.error("Failed to change day type");

        console.error(e);
      });
  };

  const setOfficeDaysColumnHeaders = (data: any) => {
    if (!data) return;

    const firstTableItem = data[0];

    const result = currentWeekTableColumns(firstTableItem, handleChangeDayType);

    setColumns(result);
  };

  const getAttendanceHandler = () => {
    const data = fillEmployeesSchedules(
      attendanceData.data,
      {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        sundayFirst: companyAndSiteSettings.first_day_of_week === "Sunday",
        addWeekends: false
      },
      employeeSettings
    );

    setOfficeDaysColumnHeaders(data);
    setRows(data);
  };

  /* ------------------ EFFECTs ------------------ */

  useEffect(() => {
    if (!attendanceData || isLoading || isEmployeeSettingsLoading || !employeeSettings) return;

    getAttendanceHandler();
  }, [attendanceData, isLoading, employeeSettings, isEmployeeSettingsLoading]);

  useEffect(() => {
    setSummary({
      users: rows?.length,
      days: WORK_DAYS_COUNT
    });
  }, [rows?.length]);

  return {
    manualDayTypeSidebar,
    formattedStartDate,
    formattedEndDate,
    columns,
    rows,
    isLoading
  };
}
