export const GOOGLE_CAPTCHA_KEY = "6LcjgrUiAAAAAK6bRIEJRAnwfVTHKZCX7yFn4A6q";

export const LOGIN_LOGO_SIZE = 249;

/**
 * http://emailregex.com/
 */
export const RGX_EMAIL =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

// @ts-ignore
export const RGX_FILENAME: RegExp = /filename="(?<filename>.*)"/;

export const FACILITY_MANAGER = "Facility manager";

export const HR_MANAGER = "HR manager";

export const COMPANY_GENERAL_MANAGEMENT = "Company general management";

export const USER_MANAGEMENT_TABLE_NAME = "User management";

export const WORKSTATION_TABLE_NAME = "Workstations";

export const STORAGE_USER_INFO = "USER_INFO";

export const STORAGE_COMPANY_SETTINGS = "COMPANY_SETTINGS";

export const STORAGE_SITE_AND_BUILDING = "SITE_AND_BUILDING_FILTERS";

export const STORAGE_FLOORS_FILTERS = "FLOORS_FILTERS";

export const STORAGE_ROLE = "ROLE";

export const STORAGE_NAVIGATION_RESOURCES = "NAVIGATION_RESOURCES";

export const STORAGE_SELECTED_TEAM_MEMBER = "SELECTED_TEAM_MEMBER_ID";

export const OVERLAP_WITH_MANAGER = "Overlap With Manager";

export const GENDER_INCLUSION = "Gender Inclusion";

export const TEAM_MEMBER_OVERLAP = "Team Member Overlap";

export const ORGANIZATION_CONNECTIONS = "Organization Connections";

export const AVERAGE_DAYS_PER_WEEK = "Average Days Per Week";

export const SUSTAINABILITY = "Sustainability";

export const CHART_DAY_LABELS = ["MON", "TUE", "WED", "THU", "FRI"];

export const AUTO_PLAY_INTERVAL = 5000;

export const NEIGHBORHOODS_PAGE_NAME = "Neighborhoods";

export const INTERACTION_TYPE_SITE_EVENT = "SITE_EVENT";

export const INTERACTION_TYPE_INTEREST_GROUP = "INTEREST_GROUP";

export const WORK_DAYS_COUNT = 5;

/**
 * ms
 */
export const POLING_INTERVAL = 30000;

export const MOMENT_DATE_FORMAT = "YYYY-MM-DD";

export const API_DATE_FORMAT = "yyyy-MM-dd";

export const URL_CONGRATULATION_CONFIRM_PLAN = "https://www.bwith.ai/thanks-for-confirming";

export const EMPLOYEE_STATUS = {
  suspended: "SUSPENDED"
};

export const DAY_TYPES = {
  home: "home",
  office: "office",
  off: "off",
  sick: "sick",
  travel: "travel",
  nonWorking: "non-working",
  armyService: "army-service"
};

export const ARRIVED = "ARRIVED";

export const EXPORT_ATTENDANCE_TYPE = {
  daysPerWeek: "daysPerWeek",
  employeesPerDay: "employeesPerDay",
  employeesPlan: "employeesPlan",
  workstationsPlan: "workstationsPlan",
  singleDay: "singleDay"
};
