import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Divider, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";

import { RemoveButton } from "pages/UserEditPage/style/userEditPageStyle";

import FormInputDate from "shared/components/FormInputs/Date/FormInputDate";
import FormInputDropdown from "shared/components/FormInputs/Dropdown/FormInputDropdownDeprecated";
import FormInputText from "shared/components/FormInputs/Text/FormInputText";
import Sidebar from "shared/components/Sidebar/Sidebar";

import useAdminSettingsHolidaySidebarLogic from "./hooks/useAdminSettingsHolidaySidebarLogic";

const AdminSettingsHolidaySidebar = ({ isInfo, onClose, isOpen, onDelete, ...props }) => {
  const { holiday, onSubmit, reset, isDirty, control, sitesOptions, isLoading, isSuccess, error } =
    useAdminSettingsHolidaySidebarLogic({
      ...props
    });

  return (
    <Sidebar
      isOpen={isOpen}
      setIsOpen={onClose}
      history={history}
      onResolve={onSubmit}
      resetForm={reset}
      isDirty={isDirty}
      resolveLabel={isInfo ? "Update" : "Add"}
      isDrawer={true}
      isSuccess={isSuccess}
      isLoading={isLoading}
      error={error}
      shouldValidate
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight="700">
            {isInfo ? (holiday?.name ? holiday.name : "National Holiday") : "Add National Holiday"}
          </Typography>
        </Grid>
      </Grid>

      <Grid container rowSpacing={1} spacing={2} mt={4}>
        <Grid item xs={12}>
          <FormInputDropdown
            name="sites"
            label="Sites*"
            control={control}
            rules={{ required: "Site is required" }}
            data={sitesOptions}
            isMultiple={true}
            placeholder="Select one or more sites"
            disabled={isInfo}
          />
        </Grid>

        <Grid item xs={12}>
          <FormInputText
            name="name"
            label="Holiday name*"
            control={control}
            rules={{ required: "Holiday name is required" }}
            placeholder="Enter holiday name"
            disabled={isInfo}
          />
        </Grid>

        <Grid item xs={12} style={{ zIndex: 10 }}>
          <FormInputDate
            name="date"
            label="Holiday date*"
            rules={{ required: "Holiday date is required" }}
            control={control}
            minDate={moment().toDate()}
            disabled={isInfo}
          />
        </Grid>

        {isInfo ? (
          <Grid item xs={12}>
            <Divider color="#BABEC5" sx={{ marginTop: "36px" }} />

            <Box mt={4} mx={"auto"} style={{ width: 240 }}>
              <RemoveButton onClick={onDelete}>
                <DeleteIcon fontSize="small" sx={{ color: "#D52B1E" }} />
                <Typography color="#D52B1E" pl={1}>
                  Delete holiday
                </Typography>
              </RemoveButton>
            </Box>
          </Grid>
        ) : null}
      </Grid>
    </Sidebar>
  );
};

AdminSettingsHolidaySidebar.propTypes = {
  isOpen: PropTypes.bool,
  isInfo: PropTypes.bool,
  holidayId: PropTypes.number,
  onClose: PropTypes.func,
  onDelete: PropTypes.func
};

export default AdminSettingsHolidaySidebar;
