import { Box, Grid, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { addDays } from "date-fns";
import React from "react";
import { useSelector } from "react-redux";

import { selectUsersByBuildingOptions } from "core/selectors";

import Sidebar from "shared/components/Sidebar/Sidebar";
import { DAY_TYPES } from "shared/constants";
import { capitalizeFirstLetter } from "shared/lib";

import FormInputAutocomplete from "../FormInputs/Autocomplete/FormInputAutocomplete";
import FormInputDate from "../FormInputs/Date/FormInputDate";
import FormInputDropdown from "../FormInputs/Dropdown";
import useManualDayTypeSidebarLogic from "./useManualDayTypeSidebarLogic";

type ManualDayTypeSidebarProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose: () => void;
};

const mapStateToProps = (state: any) => ({
  usersByBuildingOptions: selectUsersByBuildingOptions(state)
});

const dayTypeOptions = Object.values(DAY_TYPES).map(option => {
  if (option === DAY_TYPES.armyService) {
    return {
      option: "Army duty",
      value: DAY_TYPES.armyService
    };
  }

  return {
    option: capitalizeFirstLetter(option),
    value: option
  };
});

const ManualDayTypeSidebar = ({ isOpen, setIsOpen, onClose }: ManualDayTypeSidebarProps) => {
  const { control, errors, reset, onSubmit, error, isLoading, isSuccess, isSubmitting, isValid } =
    useManualDayTypeSidebarLogic();

  const { usersByBuildingOptions } = useSelector(mapStateToProps);

  return (
    // @ts-ignore
    <Sidebar
      onResolve={onSubmit}
      history={history}
      isOpen={isOpen}
      isDrawer={true}
      setIsOpen={setIsOpen}
      onClose={onClose}
      resolveLabel="Update"
      isLoading={isSubmitting}
      isSuccess={isSuccess}
      error={errors || error}
      resetForm={reset}
      shouldValidate={isValid}
    >
      <Box>
        <Typography variant="h5" fontWeight="700">
          Manual Entry
        </Typography>
      </Box>

      <Grid container rowSpacing={1} spacing={1} mb={2}>
        <Grid item width="100%" mt={2}>
          <Divider color="#8D9096" />
        </Grid>
      </Grid>

      <Grid item xs={12} mt={2}>
        {/* @ts-ignore */}
        <FormInputAutocomplete
          label="Select user*"
          control={control}
          name="employee"
          data={usersByBuildingOptions}
          placeholder="Select employee"
        />
      </Grid>

      <Grid item xs={12} mt={2}>
        {/* @ts-ignore */}
        <FormInputDate
          label="Select Date*"
          control={control}
          name="date"
          showYearDropdown
          fullWidth
          showPopperArrow={false}
          rules={{ required: "Date is required" }}
          maxDate={addDays(new Date(), -1)}
        />
      </Grid>

      <Grid item xs={12} mt={2}>
        {/* @ts-ignore */}
        <FormInputDropdown
          label="Select Day type*"
          control={control}
          name="dayType"
          data={dayTypeOptions}
          isMultiple={false}
          placeholder="Select day type"
          disabled={isLoading}
        />
      </Grid>
    </Sidebar>
  );
};

export default ManualDayTypeSidebar;
