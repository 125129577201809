import styled from "@mui/material/styles/styled";
import React from "react";
// @ts-ignore
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";

import CustomizedLabel from "shared/components/CustomizedLabel/CustomizedLabel";

type FormInputDateProps = {
  name: string;
  control: any;
  label: string;
  rules?: object;
  disabled?: boolean;
  fullWidth?: boolean;
  minDate?: string | Date;
  maxDate?: string | Date;
  showYearDropdown?: boolean;
  showPopperArrow?: boolean;
};

const FormInputDate = ({
  name,
  control,
  label,
  rules,
  minDate = "",
  maxDate = "",
  showYearDropdown = false,
  disabled = false,
  fullWidth,
  showPopperArrow = true
}: FormInputDateProps) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            {label && <CustomizedLabel text={label} error={!!error} />}
            <Picker
              selected={value}
              onChange={onChange}
              placeholderText="Select date"
              dateFormat="yyyy-MM-dd"
              showYearDropdown={showYearDropdown}
              minDate={minDate}
              maxDate={maxDate || (showYearDropdown && new Date())}
              yearDropdownItemNumber={100}
              scrollableYearDropdown
              disabled={disabled}
              wrapperClassName={fullWidth && "date-picker-full-width"}
              showPopperArrow={showPopperArrow}
              popperPlacement="bottom-start"
            />
          </>
        )}
      />
    </>
  );
};

const Picker = styled(DatePicker)(({ theme }) => ({
  border: "1px solid #c4c4c4",
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(1.2, 1.8),
  width: "100%",
  color: "#00203E",
  "&::placeholder": {
    fontSize: "1rem",
    opacity: "0.6"
  },

  [theme.breakpoints.down("xxl")]: {
    "&::placeholder": {
      fontSize: "0.8rem"
    }
  }
}));

export default FormInputDate;
