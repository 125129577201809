import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { selectBuildingFilter, selectFilteredNeighborhoodsList, selectSiteFilter } from "core/selectors";

import { useNeighborhoodsQuery } from "features/neighborhoods/neighborhoodApiSlice";
import { useDeleteNeighborhoodMutation } from "features/neighborhoods/neighborhoodApiSlice";
import { setNeighborhoodsList } from "features/neighborhoods/neighborhoodsSlice";

import { useToggle } from "shared/hooks/useToggle";
import { getMappedResponse } from "shared/lib/getters";

export default function useAdminSettingsNeighborhoods() {
  const dispatch = useDispatch();
  const neighborhoodsSidebar = useToggle();

  const list = useSelector(selectFilteredNeighborhoodsList);
  const site = useSelector(selectSiteFilter);
  const building = useSelector(selectBuildingFilter);
  const [neighborhoodName, setNeighborhoodName] = useState<string | null>(null);

  const { data, isLoading } = useNeighborhoodsQuery({ site }, { skip: !site });
  const [deleteNeighborhood, { error, isSuccess }] = useDeleteNeighborhoodMutation();

  useEffect(() => {
    if (data && !isLoading) {
      const mappedData = getMappedResponse(data.neighborhoods);
      dispatch(setNeighborhoodsList(mappedData));
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Done.");
    }
    if (error) {
      toast.error((error as any).data.message);
    }
  }, [error, isSuccess]);

  const onEdit = (name: string) => {
    setNeighborhoodName(name);

    neighborhoodsSidebar.on();
  };

  const onAdd = () => {
    setNeighborhoodName(null);

    neighborhoodsSidebar.on();
  };

  const onDelete = async (neighborhoodName: string) => {
    try {
      await deleteNeighborhood({ name: neighborhoodName, site, building });
    } catch (e) {
      console.error(e);
    }
  };

  const onClose = (value: boolean) => {
    setNeighborhoodName(null);
    neighborhoodsSidebar.set(value);
  };

  return {
    list,
    onEdit,
    onDelete,
    onAdd,
    isLoading,
    neighborhoodsSidebar,
    neighborhoodName,
    onClose
  };
}
