import React, { useCallback } from "react";

import DataTable from "shared/components/DataTable/DataTable";
import ExportFileSidebar from "shared/components/ExportFileSidebar/ExportFileSidebar";

import AttendanceSummary from "../AttendanceSummary/AttendanceSummary";
import TotalArrivalsFooter from "../TotalArrivalsFooter";
import PreviousWeeksAgenda from "./components/PreviousWeeksAgenda";
import useExportAttendance from "./hooks/useExportAttendance";
import usePreviousWeeksTabLogic, { NUMBER_OF_WEEKS, TUsePreviousWeeksTabLogic } from "./hooks/usePreviousWeeksTabLogic";
import { Container, styles } from "./style/previousWeeksTabStyle";

const PreviousWeeksTab = (props: TUsePreviousWeeksTabLogic) => {
  const {
    rows,
    columns,
    monthPickerValue,
    isLoading,
    onSelectMonth,
    onRenderFooterComponent,
    avgEmployeeDays,
    avgEmployeeArrivals,
    avgEmployees,
    fromDate,
    toDate
  } = usePreviousWeeksTabLogic(props);

  const { exportFileSidebar, handleExportFile, isLoadingExportFile, isSuccessExportFile, errorExportFile } =
    useExportAttendance();

  const renderFooterComponent = useCallback(() => {
    const data = onRenderFooterComponent();

    return (
      <TotalArrivalsFooter title="Total actual arrivals" titleWidth={"660px"} arrivalsData={data} showBorder={true} />
    );
  }, [onRenderFooterComponent]);

  const renderSummary = useCallback(
    () => (
      <AttendanceSummary
        avgEmployeeDays={avgEmployeeDays}
        avgEmployeeArrivals={avgEmployeeArrivals}
        avgEmployees={avgEmployees}
      />
    ),
    [avgEmployeeDays, avgEmployeeArrivals, avgEmployees]
  );

  return (
    <>
      <Container>
        <DataTable
          rows={rows}
          rowIdFieldName={"employeeId"}
          columns={columns}
          showDeleteColumn={false}
          showEditColumn={false}
          showInfoColumn={false}
          showMonthPicker={true}
          showSiteAndBuilding
          showFloorSelector
          showDepartmentSelector
          showSearch
          autoHeight={false}
          isLoading={isLoading}
          monthPickerProps={{
            onSelectMonth,
            value: monthPickerValue,
            defaultValue: NUMBER_OF_WEEKS,
            labelText: `Past ${NUMBER_OF_WEEKS} weeks`
          }}
          sx={styles.tableStyle}
          renderFooterComponent={renderFooterComponent}
          renderToolbarDetailsComponent={renderSummary}
          onExportFile={exportFileSidebar.on}
          perPage={25}
        />

        <PreviousWeeksAgenda />
      </Container>

      <ExportFileSidebar
        startDate={fromDate}
        endDate={toDate}
        isOpen={exportFileSidebar.enable}
        setIsOpen={exportFileSidebar.set}
        onClose={exportFileSidebar.off}
        onExport={handleExportFile}
        loading={isLoadingExportFile}
        success={isSuccessExportFile}
        error={errorExportFile}
      />
    </>
  );
};

export default PreviousWeeksTab;
