import Button from "@mui/material/Button";
import styled from "@mui/material/styles/styled";
import PropTypes from "prop-types";
import React from "react";

const FilterButton = ({ children, variant = "secondary", onClick }) => {
  return (
    <CustomizedFilterButton variant={variant} onClick={onClick}>
      {children}
    </CustomizedFilterButton>
  );
};

FilterButton.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

const CustomizedFilterButton = styled(Button)(({ theme }) => ({
  background: "#fff",
  border: "1px solid #000",
  borderRadius: theme.spacing(3),
  padding: theme.spacing(1, 2),
  minWidth: "152px",
  display: "flex",
  alignItems: "center"
}));

export default FilterButton;
