import React from "react";
import { Controller } from "react-hook-form";

import CustomizedLabel from "shared/components/CustomizedLabel/CustomizedLabel";
import Select from "shared/components/Select";

type FormInputDropdownProps = {
  name: string;
  control: any;
  label: string;
  rules?: object;
  placeholder?: string;
  data?: any[];
  styles?: object;
  isMultiple?: boolean;
  defaultValue?: any;
  defaultLabel?: string;
  disabled?: boolean;
  readonly?: boolean;
};

const FormInputDropdown = ({
  name,
  control,
  label,
  rules,
  placeholder,
  data,
  styles,
  isMultiple,
  defaultValue,
  defaultLabel,
  disabled,
  readonly
}: FormInputDropdownProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <>
            <CustomizedLabel text={label} error={!!error} />
            {/* @ts-ignore */}
            <Select
              helperText={error ? error.message : undefined}
              error={!!error}
              handleChange={onChange}
              values={data ?? []}
              selectedValue={isMultiple ? value : [value]}
              fullWidth
              placeholder={placeholder}
              selectStyles={styles}
              isMultiple={isMultiple}
              defaultValue={defaultValue}
              defaultLabel={defaultLabel}
              disabled={disabled}
              readonly={readonly}
            />
          </>
        );
      }}
    />
  );
};

export default FormInputDropdown;
